import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy as AngularTitleStrategy } from '@angular/router';

const DEFAULT_TITLE = 'Malwee B2B';

@Injectable()
export class TitleStrategy extends AngularTitleStrategy {
	constructor(private readonly title: Title) {
		super();
	}

	override updateTitle(routerState: RouterStateSnapshot) {
		const title = this.buildTitle(routerState);

		if (title !== undefined) {
			this.title.setTitle(`${title} - ${DEFAULT_TITLE}`);
			return;
		}
		this.title.setTitle(DEFAULT_TITLE);
	}
}
