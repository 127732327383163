<div class="row">
	<div class="col-24 text-center my-5">
		<i class="bi bi-check-circle-fill text-success" [style.font-size]="'5rem'"></i>
		<h2 class="mb-4">Pedido realizado com sucesso!</h2>
		<h5>O número do seu pedido é</h5>
		<h4 class="text-primary mb-4">{{ erpSalesOrderCode }}</h4>
		<p>Clique no botão abaixo para acompanhar o status do pedido.</p>
		<button class="btn btn-success" (click)="goToMyOrders()">Ir para meus pedidos</button>
	</div>
</div>
