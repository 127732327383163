import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';

import { HeroComponent } from '@proj-b2b/app/components/hero/hero.component';
import { NavbarComponent } from '@lib-shared/components';

@Component({
	standalone: true,
	selector: 'app-cart-wrapper',
	imports: [RouterOutlet, CommonModule, RouterModule, HeroComponent, NavbarComponent],
	templateUrl: './cart-wrapper.component.html',
})
export class CartWrapperComponent {}
