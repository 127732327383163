import { fromBase64 } from '@lib-core/helpers/base64.helper';
import { isStringNullOrEmpty } from '@lib-core/helpers/string.helper';
import { ExtensionByMimeTypeMap, MIMEType } from '@lib-core/enums';

function downloadByteArray(base64data: ArrayBuffer, type: MIMEType, fileName: string = null): void {
	const blob = new Blob([base64data], { type });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');

	a.href = url;
	a.target = '_new';
	a.download = createFileName(fileName, type);
	a.click();
}

function downloadFile(base64data: string, type: MIMEType, fileName: string = null): void {
	if (isStringNullOrEmpty(base64data)) return;

	const blobData = stringToBlob(base64data);
	const blob = new Blob([blobData], { type });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');

	a.href = url;
	a.target = '_new';
	a.download = createFileName(fileName, type);
	a.click();
}

function stringToBlob(base64Data: string): Uint8Array {
	const data = fromBase64(base64Data);
	const buffer = new ArrayBuffer(data.length);
	const array = new Uint8Array(buffer);

	for (let i = 0; i < data.length; i++) {
		array[i] = data.charCodeAt(i);
	}

	return array;
}

function download(value: string, name = '', type: MIMEType): void {
	const a = document.createElement('a');
	a.href = value;
	a.target = '_blank';
	a.download = createFileName(name, type);
	a.click();
}

function createFileName(fileName: string, type: MIMEType, unique = true): string {
	const timestamp = new Date().getTime();

	if (!fileName) {
		return `${timestamp}.${ExtensionByMimeTypeMap[type]}`;
	}

	if (unique) {
		return `${fileName}_${timestamp}.${ExtensionByMimeTypeMap[type]}`;
	}

	return `${fileName}.${ExtensionByMimeTypeMap[type]}`;
}

export { download, downloadFile, downloadByteArray };
