import LocalePtBr from '@angular/common/locales/pt';

import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { IMAGE_CONFIG, registerLocaleData } from '@angular/common';
import { TitleStrategy as AngularTitleStrategy, provideRouter } from '@angular/router';
import { withInterceptors, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { APP_ROUTES } from '@proj-b2b/app/router/app.routes';
import { TitleStrategy } from '@lib-core/strategies';
import { AuthInterceptor } from '@lib-core/interceptors';

registerLocaleData(LocalePtBr);

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(APP_ROUTES),
		{
			provide: AngularTitleStrategy,
			useClass: TitleStrategy,
		},
		provideHttpClient(withInterceptorsFromDi(), withInterceptors([AuthInterceptor])),
		{
			provide: IMAGE_CONFIG,
			useValue: {
				disableImageSizeWarning: true,
				disableImageLazyLoadWarning: true,
			},
		},
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
	],
};
