<div class="container-xxl h-100" pageClassList="bg-primary bg-gradient">
	<div class="row align-items-center justify-content-center h-100">
		<div class="col-24 col-sm-18 col-md-14 col-lg-8 col-xxl-7">
			<div class="p-5 bg-white rounded">
				<div class="text-center mt-2 mb-5">
					<img src="./assets/images/logo.png" alt="logo" />
				</div>
				<form class="needs-validation" [formGroup]="loginForm" (ngSubmit)="login()" novalidate="">
					<div class="mb-3">
						<label class="mb-2 text-muted" for="username">Nome de usuário ou e-mail</label>
						<input
							#username
							id="username"
							type="email"
							(keyup)="clear()"
							(change)="clear()"
							class="form-control"
							formControlName="username"
							[ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
						<div class="invalid-feedback">Por favor informe um login válido</div>
					</div>
					<div class="mb-3">
						<div class="mb-2 w-100">
							<label class="text-muted" for="password">Senha</label>
						</div>
						<input
							#password
							type="password"
							id="password"
							(keyup)="clear()"
							(change)="clear()"
							class="form-control"
							formControlName="password"
							[ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
						<div class="invalid-feedback">Por favor informe uma senha válida</div>
					</div>
					<div class="alert alert-danger" *ngIf="error && submitted">
						{{ error }}
					</div>
					<div class="text-center">
						<input #remember type="checkbox" id="remember" name="remember" class="form-check-input me-2" formControlName="remember" />
						<label for="remember" class="form-check-label">Lembrar dados</label>
					</div>
					<button type="submit" class="btn btn-primary w-100 mt-3">
						<i class="bi bi-person-fill-lock me-1"></i>
						<span>Entrar</span>
					</button>
				</form>
			</div>
		</div>
	</div>
</div>
