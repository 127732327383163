import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';

import { SESSION_COOKIE_LIST } from '@lib-core/constants';

import { clear } from '@lib-core/helpers';
import { ApiResponseModel, CustomerModel } from '@lib-core/models';
import { LoginResponseDto } from '@lib-core/dtos';
import { AuthStore, AuthResource, AuthenticateOptions, AUTH_ROUTE_NAMES } from '@lib-auth/auth';
import { CustomerStore, GalleryRequestStore, LoginDetailsStore, ModalityStore, RepresentativeStore, SalesPeriodStore, SalesTeamStore } from '@lib-core/stores';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private authStore: AuthStore,
		private authResource: AuthResource,
		private customerStore: CustomerStore,
		private modalityStore: ModalityStore,
		private salesTeamStore: SalesTeamStore,
		private salesPeriodStore: SalesPeriodStore,
		private loginDetailsStore: LoginDetailsStore,
		private galleryRequestStore: GalleryRequestStore,
		private representativeStore: RepresentativeStore,
	) {}

	authenticate(authOptions: AuthenticateOptions): Observable<ApiResponseModel<LoginResponseDto>> {
		const observable = new Subject<ApiResponseModel<LoginResponseDto>>();

		if (!this.validate(authOptions.username, authOptions.password)) {
			observable.next(null);
			observable.complete();
			return observable;
		}

		this.authResource
			.authenticate(authOptions)
			.pipe(take(1))
			.subscribe(response => {
				if (response.success) {
					this.loginSuccess(response.data, authOptions.remember);
					observable.next(response);
					observable.complete();
					return;
				}

				this.logout(false);
				observable.next(response);
				observable.complete();
			});

		return observable;
	}

	authenticateExternal(token: string, queryParams: any): Observable<ApiResponseModel<LoginResponseDto>> {
		const observable = new Subject<ApiResponseModel<LoginResponseDto>>();

		this.authResource
			.authenticateExternal(token)
			.pipe(take(1))
			.subscribe({
				next: response => {
					if (response.success) {
						this.loginSuccess(response.data, false, queryParams);
						observable.next(response);
						observable.complete();
						return;
					}

					observable.next(response);
					observable.complete();
				},
			});

		return observable;
	}

	logout(reload = true) {
		this.authStore.clear();
		SESSION_COOKIE_LIST.forEach(cookie => clear(cookie));

		if (reload) {
			window.location.href = AUTH_ROUTE_NAMES.login.url;
		}
	}

	private loginSuccess(response: LoginResponseDto, remember = false, queryParams = null) {
		this.authStore.set({
			token: response.token,
			username: response.username,
		});

		this.customerStore.set(response.customer);
		this.modalityStore.setList(response.modalities);
		this.salesTeamStore.setList(response.salesTeams);
		this.salesPeriodStore.setList(response.salesPeriods);
		this.representativeStore.setList([response.representative]);

		this.setStoreByQueryParam(queryParams);

		if (!remember) {
			this.loginDetailsStore.clear();
			return;
		}

		this.loginDetailsStore.set({
			username: response.username,
			rememberMe: remember,
		});
	}

	private setStoreByQueryParam(queryParams: any) {
		const request = this.galleryRequestStore.get();

		let hasChanges = false;

		if (queryParams?.modalidade) {
			request.modalityCode = queryParams.modalidade;
			hasChanges = true;
		}

		if (queryParams?.timeVendas) {
			request.salesTeamCode = queryParams.timeVendas;
			hasChanges = true;
		}

		if (!hasChanges) {
			return;
		}

		this.galleryRequestStore.set(request);
	}

	private validate(username: string, password: string) {
		return username !== '' && password !== '' && username != null && password != null;
	}
}
