import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ImageModel } from '@lib-core/models';
import { ProductImageComponent } from '@proj-b2b/app/components/product-image/product-image.component';
import { leftArrowUnicode, rightArrowUnicode } from '@lib-core/constants/unicode-characters-contants';

@Component({
	standalone: true,
	selector: 'app-vertical-product-carousel',
	templateUrl: './vertical-product-carousel.component.html',
	imports: [CommonModule, ProductImageComponent],
})
export class VerticalProductCarouselComponent {
	prevIcon = leftArrowUnicode;
	nextIcon = rightArrowUnicode;
	buttonWidth = '30px';

	@Input()
	height: string | number = 100;

	@Input()
	imageList: ImageModel[] = [];

	@Input()
	classList: string = '';

	@Output()
	select = new EventEmitter<string>();

	@ViewChild('widgetsContent', { read: ElementRef })
	public widgetsContent: ElementRef<any>;

	get thumbnailHeight(): string {
		return typeof this.height === 'string' ? this.height : `${this.height}px`;
	}

	get internalClassList(): string {
		return 'd-flex flex-row overflow-hidden flex-row gap-2';
	}

	get hasImages(): boolean {
		return this.imageList?.length > 0;
	}

	scrollLeft() {
		this.widgetsContent.nativeElement.scrollTo({ left: this.widgetsContent.nativeElement.scrollLeft - 150, behavior: 'smooth' });
	}

	scrollRight() {
		this.widgetsContent.nativeElement.scrollTo({ left: this.widgetsContent.nativeElement.scrollLeft + 150, behavior: 'smooth' });
	}

	onImageClick(imageUrl: string) {
		this.select.emit(imageUrl);
	}
}
