import { Injectable } from '@angular/core';
import { PaymentConditionResource } from '@lib-core/resources';

@Injectable({
	providedIn: 'root',
})
export class PaymentConditionService {
	constructor(private paymentConditionCustomer: PaymentConditionResource) {}

	queryPaymentConditionsByCustomer(customerCode: string, modalityCode: string) {
		return this.paymentConditionCustomer.queryPaymentConditionsForCustomer(customerCode, modalityCode);
	}
}
