<div [class]="classList" [style.height]="containerHeight" class="d-flex justify-content-center align-items-center">
	<div [class.cursor--pointer]="enableZoom">
		<img [alt]="alt" [src]="imageUrl" [style.width]="imageWidth" [style.height]="imageHeight" (click)="showPreviewModal()" (keyup)="showPreviewModal()" />
	</div>
</div>

<ng-template class="pt-4" #previewModal let-modal>
	<div class="modal-body text-center" [style.height]="'80vh'">
		<div class="position-absolute start-0 top-0">
			<button type="button" class="btn-close m-3" aria-label="Close" (click)="modal.dismiss()"></button>
		</div>
		<img [alt]="alt" [src]="imageUrl" [style.height]="'100%'" />
	</div>
</ng-template>
