import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResource } from '@lib-core/resources';
import { ApiResponseModel, CustomerModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class CustomerResource {
	private baseUrl = 'Customer';

	constructor(private httpResource: HttpResource) {}

	listCustomersByRepresentative(representativeCode: string): Observable<ApiResponseModel<CustomerModel[]>> {
		return this.httpResource.get<ApiResponseModel<CustomerModel[]>>(`${this.baseUrl}/ListCustomersByRepresentative/${representativeCode}`, {
			showLoader: true,
			loaderMessage: 'Buscando clientes',
		});
	}

	GetCustomerByCode(customerCode: string): Observable<ApiResponseModel<CustomerModel[]>> {
		return this.httpResource.get<ApiResponseModel<CustomerModel[]>>(`${this.baseUrl}/GetByCode/${customerCode}`, {
			showLoader: true,
			loaderMessage: 'Buscando informações do cliente',
		});
	}
}
