import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';

import { AuthStore } from '@lib-auth/auth';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
	const authStore = inject(AuthStore);
	const token = authStore.get()?.token;

	if (token) {
		req = req.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
	return next(req).pipe();
};
