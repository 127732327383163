import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ENVIRONMENT } from '../../../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	private visibleSubject: BehaviorSubject<boolean>;

	constructor() {
		this.visibleSubject = new BehaviorSubject<boolean>(false);

		this.visibleSubject.subscribe((isVisible: boolean) => {
			document.body.classList.toggle('sidebar-open', isVisible);
		});
	}

	get isVisible() {
		return this.visibleSubject.getValue();
	}

	get isVisible$() {
		return this.visibleSubject.asObservable();
	}

	get useMenuOnSidebar(): boolean {
		return ENVIRONMENT.backOfficeSettings.useMenuOnSidebar;
	}

	open() {
		this.visibleSubject.next(true);
	}

	close() {
		this.visibleSubject.next(false);
	}

	toggle() {
		this.visibleSubject.next(!this.isVisible);
	}
}
