import { Routes } from '@angular/router';
import { AuthGuard } from '@lib-auth/auth';

import { CartResolver } from '@lib-core/resolvers';
import { CustomerGuard } from '@lib-core/guards';
import { CART_PATH, CART_ROUTE_NAMES } from '@proj-b2b/app/modules/cart/cart.constants';
import { CartWrapperComponent } from '@proj-b2b/app/modules/cart/cart-wrapper.component';
import { CartDetailsComponent } from '@proj-b2b/app/modules/cart/cart-details/cart-details.component';

export const CART_ROUTE_LIST: Routes = [
	{
		path: CART_PATH,
		component: CartWrapperComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: CART_ROUTE_NAMES.details.path,
			},
			{
				path: CART_ROUTE_NAMES.details.path,
				title: CART_ROUTE_NAMES.details.title,
				component: CartDetailsComponent,
				resolve: { CartResolver },
				canActivate: [AuthGuard, CustomerGuard],
				data: {
					cartResolverDetails: true,
					validateStock: true
				},
			},
		],
	},
];
