import { Injectable } from '@angular/core';

import { BaseStore } from '@lib-core/stores';
import { CART_COUNT_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class CartCountStore extends BaseStore<number> {
	constructor() {
		super(CART_COUNT_COOKIE_NAME, false);
	}

	override set(value: number): void {
		super.set(value);
	}

	override clear(): void {
		super.set(0);
	}
}
