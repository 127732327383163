import { GALLERY_DEFAULT_PAGE, GALLERY_DEFAULT_PAGE_SIZE } from '@lib-core/constants';

export class GalleryRequestDto {
	public productCode: string = null;
	public salesPeriod: string = null;
	public customerCode: string = null;
	public modalityCode: string = null;
	public salesTeamCode: string = null;
	public productDescription: string = null;
	public representativeCode: string = null;

	public priceTo?: number;
	public priceFrom?: number;

	public sizes: string[] = [];
	public colors: string[] = [];
	public brands: string[] = [];
	public genders: string[] = [];
	public species: string[] = [];
	public seasons: string[] = [];
	public segments: string[] = [];
	public categories: string[] = [];
	public collections: string[] = [];
	public productGroups: string[] = [];

	public page: number = GALLERY_DEFAULT_PAGE;
	public pageSize: number = GALLERY_DEFAULT_PAGE_SIZE;
}
