import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';

import { CustomerStore } from '@lib-core/stores';

export const CustomerGuard: CanActivateFn = (route, state) => {
	const router = inject(Router);
	const customerStore = inject(CustomerStore);

	if (!customerStore.isValid()) {
		router.navigate(['cliente/selecionar'], { state: { redirectUrl: state.url } });
		return false;
	}
	return true;
};
