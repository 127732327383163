import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../../environments/environment';
import { HttpResource } from '@lib-core/resources/http.resource';
import { SalesReturnType } from '@lib-core/enums';
import { SalesFieldModel, SalesReturnCampoDependente, SalesReturnDetailModel, SalesReturnModel, SalesReturnProcessoModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class SalesReturnResource {
	private baseUrl = ENVIRONMENT.geoSettings.url;

	constructor(private httpResource: HttpResource) {}

	get userAndPassword() {
		return {
			user: ENVIRONMENT.geoSettings.login,
			password: ENVIRONMENT.geoSettings.password,
		};
	}

	get baseLoaderOptions() {
		return {
			isExternal: true,
			showLoader: true,
		};
	}

	getFluxoDetails(fluxo: number) {
		return this.httpResource.post(`${this.baseUrl}/processos/fluxo/${fluxo}`, this.userAndPassword, this.baseLoaderOptions);
	}

	loadFieldData(fieldId: number, customerCode: string): Observable<SalesFieldModel[]> {
		return this.httpResource.post(
			`${this.baseUrl}/processos/campo/campo-dependente`,
			{
				...this.userAndPassword,
				idCampoDependente: fieldId,
				codClienteERP: customerCode,
				camposDependentes: null,
			},
			this.baseLoaderOptions,
		);
	}

	loadFieldDataWithDependent(fieldId: number, customerCode: string, dependentFieldList: SalesReturnCampoDependente[]): Observable<SalesFieldModel[]> {
		return this.httpResource.post(
			`${this.baseUrl}/processos/campo/campo-dependente`,
			{
				...this.userAndPassword,
				idCampoDependente: fieldId,
				codClienteERP: customerCode,
				camposDependentes: dependentFieldList ?? [],
			},
			this.baseLoaderOptions,
		);
	}

	lisReturnOrders(cnpj: string, returnType: SalesReturnType): Observable<SalesReturnModel[]> {
		const body = {
			...this.userAndPassword,
			fluxos: [returnType.toString()],
		};

		return this.httpResource.post<SalesReturnModel[]>(`${this.baseUrl}/processos/processos/lista/sintetico/${cnpj}`, body, {
			...this.baseLoaderOptions,
			loaderMessage: 'Carregando lista de devoluções',
		});
	}

	getReturnOrderDetails(cnpj: string, salesReturnId: number, returnType: SalesReturnType): Observable<SalesReturnDetailModel> {
		return this.httpResource.post<SalesReturnDetailModel>(
			`${this.baseUrl}/processos/processos/detalhe/${cnpj}`,
			{
				idFluxo: returnType,
				idProcesso: salesReturnId,
				somenteNomeImagem: 1,
				somenteCodigoEtapa: 1,
				...this.userAndPassword,
			},
			{
				...this.baseLoaderOptions,
				loaderMessage: 'Carregando detalhes da devolução',
			},
		);
	}

	createProcess(process: SalesReturnProcessoModel): Observable<any> {
		const body = {
			...process,
			...this.userAndPassword,
			redirectUrl: `${this.baseUrl}/processos/processo/create`,
		};

		return this.httpResource.post<SalesReturnDetailModel>('Proxy/forwarding', body, {
			...this.baseLoaderOptions,
			isExternal: false,
			loaderMessage: 'Criando sua solicitação, por favor não atualize a página',
		});
	}

	updateProcess(process: SalesReturnProcessoModel): Observable<any> {
		return this.httpResource.post<SalesReturnDetailModel>(
			'Proxy/forwarding',
			{
				...process,
				...this.userAndPassword,
				redirectUrl: `${this.baseUrl}/processos/processo/update`,
			},
			{
				...this.baseLoaderOptions,
				isExternal: false,
				loaderMessage: 'Salvando sua solicitação, por favor não atualize a página',
			},
		);
	}
}
