<div class="container-xxl h-100">
	<div class="row align-items-center justify-content-center h-100">
		<div class="col-24 text-center">
			<i class="bi bi-door-open text-primary" [style.font-size]="'5rem'"></i>
			<h3>Encerrando sua sessão...</h3>
			<p>
				Sua sessão será encerrada e você precisará entrar novamente em <strong>{{ timeout }}</strong> segundo(s)
			</p>
			<div class="d-flex gap-2 justify-content-center">
				<button class="btn btn-outline-secondary" (click)="cancel()">
					<i class="bi bi-x-lg m-1"></i>
					<span>Cancelar</span>
				</button>
				<button class="btn btn-danger" (click)="logout()">
					<i class="bi bi-arrow-bar-right m-1"></i>
					<span>Sair agora</span>
				</button>
			</div>
		</div>
	</div>
</div>
