export const ACCOUNT_PATH = 'minha-conta';
export const ACCOUNT_ROUTE = `/${ACCOUNT_PATH}`;

const RETURN_PATH = 'devolucoes';
const UP_TO_FIVE_PATH = 'ate-5-pecas';
const SIX_OR_MORE_PATH = '6-ou-mais-pecas';

export const ACCOUNT_ROUTE_NAMES = {
	info: {
		path: 'meus-dados',
		url: `${ACCOUNT_ROUTE}/meus-dados`,
		title: `Meus dados`,
	},
	customers: {
		path: 'clientes',
		url: `${ACCOUNT_ROUTE}/clientes`,
		title: `Clientes`,
	},
	downloads: {
		path: 'downloads',
		url: `${ACCOUNT_ROUTE}/downloads`,
		title: `Downloads`,
	},
	ebooks: {
		path: 'ebooks',
		url: `${ACCOUNT_ROUTE}/ebooks`,
		title: `E-Books`,
	},
	invoices: {
		path: 'notas-fiscais',
		url: `${ACCOUNT_ROUTE}/notas-fiscais`,
		title: `Notas fiscais`,
	},
	orders: {
		path: 'pedidos',
		url: `${ACCOUNT_ROUTE}/pedidos`,
		title: `Pedidos`,
	},
	titles: {
		path: 'titles',
		url: `${ACCOUNT_ROUTE}/notas-e-titulos`,
		title: `Notas e títulos`,
	},
};

export const ACCOUNT_RETURN_ROUTE_NAMES = {
	upToFive: {
		path: `${RETURN_PATH}/${UP_TO_FIVE_PATH}`,
		title: 'Devoluções até 5 peças',
		list: {
			path: 'lista',
			url: `${ACCOUNT_ROUTE}/${RETURN_PATH}/${UP_TO_FIVE_PATH}`,
			title: `Devoluções (até 5 peças)`,
		},
		new: {
			path: 'criar',
			url: `${ACCOUNT_ROUTE}/${RETURN_PATH}/${UP_TO_FIVE_PATH}/criar`,
			title: `Nova devolução (até 5 peças)`,
		},
		edit: {
			path: 'editar/:id',
			url: `${ACCOUNT_ROUTE}/${RETURN_PATH}/${UP_TO_FIVE_PATH}/editar`,
			title: `Editar devolução (até 5 peças)`,
		},
	},
	sixOrMore: {
		path: `${RETURN_PATH}/${SIX_OR_MORE_PATH}`,
		title: 'Devoluções mais de 6 peças',
		list: {
			path: 'lista',
			url: `${ACCOUNT_ROUTE}/${RETURN_PATH}/${SIX_OR_MORE_PATH}`,
			title: `Devoluções (6 ou mais peças)`,
		},
		new: {
			path: 'criar',
			url: `${ACCOUNT_ROUTE}/${RETURN_PATH}/${SIX_OR_MORE_PATH}/criar`,
			title: `Nova devolução (6 ou mais peças)`,
		},
		edit: {
			path: 'editar/:id',
			url: `${ACCOUNT_ROUTE}/${RETURN_PATH}/${SIX_OR_MORE_PATH}/editar`,
			title: `Editar devolução (6 ou mais peças)`,
		},
	},
};
