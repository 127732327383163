import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { Observable, map } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { AuthService } from '@lib-auth/auth/auth.service';
import { isStringNullOrEmpty } from '@lib-core/helpers';

@Component({
	standalone: true,
	selector: 'app-login-external',
	templateUrl: './login-external.component.html',
	imports: [NgbAlert, FormsModule, CommonModule, RouterOutlet, ReactiveFormsModule],
})
export class LoginExternalComponent implements OnInit {
	error = null;
	queryParams: any;

	constructor(private router: Router, private authService: AuthService, private activatedRoute: ActivatedRoute) {}

	get hasError(): boolean {
		return !isStringNullOrEmpty(this.error);
	}

	ngOnInit() {
		this.clear();
		this.loadData();
		this.initialize();
	}

	loadData(): void {
		this.queryParams = this.activatedRoute.snapshot.queryParams;
	}

	initialize() {
		if (!this.validate(this.queryParams?.token)) {
			return;
		}
		this.authenticate();
	}

	clear() {
		this.error = null;
	}

	validate(token: string, message?: string): boolean {
		if (isStringNullOrEmpty(token)) {
			this.error = 'Seu token de acesso não foi encontrado ou é inválido. Por favor, tente novamente com um token diferente.';
			return false;
		}

		if (message) {
			this.error = `Não foi possível autenticar com o token informado. Erro: ${message}`;
			return false;
		}

		return true;
	}

	authenticate() {
		this.authService.authenticateExternal(this.queryParams.token, this.queryParams).subscribe(response => {
			if (response?.success) {
				this.redirect();
				return;
			}
			this.validate(this.queryParams?.token, response.message);
		});
	}

	redirect() {
		if (this.queryParams?.redirectUrl) {
			this.router.navigate([this.queryParams.redirectUrl]);
			return;
		}
		this.router.navigate(['/']);
	}
}
