import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { stringToNumber } from '@lib-core/helpers';

@Pipe({
	name: 'brl',
	standalone: true,
})
export class CurrencyBrlPipe extends CurrencyPipe implements PipeTransform {
	override transform(value: any, args?: any): any {
		return super.transform(stringToNumber(value), 'BRL', args);
	}
}
