import { CommonModule, DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { DEFAULT_TIMEOUT, AppLoaderService } from '@lib-core/services';

@Component({
	standalone: true,
	selector: 'core-app-loader',
	imports: [CommonModule],
	styleUrl: './app-loader.component.scss',
	templateUrl: './app-loader.component.html',
})
export class AppLoaderComponent implements AfterViewInit {
	isDelaying = false;
	loaderMessage = '';

	constructor(
		@Inject(DOCUMENT)
		private document: Document,
		public appLoaderService: AppLoaderService
	) {}

	ngOnInit() {
		this.appLoaderService.isLoading$.subscribe((isLoading) => {
			if (isLoading) {
				this.document.body.classList.add('is-loading');
			} else {
				this.document.body.classList.remove('is-loading');
			}
		});

		this.appLoaderService.loadingMessage$.subscribe((message) => {
			this.loaderMessage = message;
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.isDelaying = true;
		}, DEFAULT_TIMEOUT);
	}
}
