import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CustomerStore } from '@lib-core/stores';
import { GalleryResource } from '@lib-core/resources';
import { GalleryRequestDto, GalleryResponseDto } from '@lib-core/dtos';
import { ApiResponseModel, SalesTeamModel, SideFilterModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class GalleryService {
	constructor(private galleryResource: GalleryResource, private customerStore: CustomerStore) {}

	query(request: GalleryRequestDto): Observable<ApiResponseModel<GalleryResponseDto>> {
		request.customerCode = this.customerStore.get()?.code;
		return this.galleryResource.query(request);
	}

	listSalesTeam(customerCode: string): Observable<ApiResponseModel<SalesTeamModel[]>> {
		return this.galleryResource.listSalesTeam(customerCode);
	}
}
