export const MULTI_SELECT_DEFAULT_CONFIG = Object.freeze({
	idField: 'code',
	textField: 'name',
	itemsShowLimit: 10,
	defaultOpen: false,
	enableCheckAll: false,
	singleSelection: false,
	allowSearchFilter: true,
	selectAllText: 'Selecionar todos',
	unSelectAllText: 'Desmarcar todos',
	searchPlaceholderText: 'Pesquisar',
	noDataAvailablePlaceholderText: 'Nenhum registro encontrado',
});
