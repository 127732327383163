import { Injectable } from '@angular/core';

import { BaseStore } from '@lib-core/stores';
import { LoginDetailsModel } from '@lib-core/models';
import { AUTH_REMEMBER_ME_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class LoginDetailsStore extends BaseStore<LoginDetailsModel> {
	constructor() {
		super(AUTH_REMEMBER_ME_COOKIE_NAME);
	}
}
