import { signal } from '@angular/core';

import * as cookieHelper from '@lib-core/helpers/cookies.helper';

export class BaseStore<T> {
	private cookieName: string;
	private useCookie: boolean;
	private storeAsObject: boolean;

	private current = signal<T>(null);

	constructor(cookieName: string, storeAsObject: boolean = true, useCookie: boolean = true) {
		this.useCookie = useCookie;
		this.cookieName = cookieName;
		this.storeAsObject = storeAsObject;
	}

	set(data: T) {
		if (this.useCookie) {
			cookieHelper.set(this.cookieName, data);
		}

		if (data === null && this.useCookie) {
			cookieHelper.clear(this.cookieName);
		}

		if (this.storeAsObject && data !== null) {
			this.current.update(() => {
				return { ...data };
			});
			return;
		}

		this.current.update(() => {
			return data;
		});
	}

	get(): T {
		let data = this.current();

		if (!data && this.useCookie) {
			data = cookieHelper.get(this.cookieName);

			if (data == null) {
				return null;
			}

			if (this.storeAsObject) {
				this.current = signal({ ...data });
				return { ...data };
			}

			this.current = signal(data);
		}

		if (data == null) {
			return null;
		}

		return data;
	}

	clear() {
		this.set(null);
	}

	isValid() {
		return !!this.get();
	}
}
