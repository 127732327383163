import { Injectable } from '@angular/core';

export interface Toast {
	type: ToastType;
	title: string;
	icon: string;
	message: string;
	delay?: number;
	classList?: string;
	action?: Function;
	actionLabel?: string;
}

export enum ToastType {
	Success = 'success',
	Warning = 'warning',
	Danger = 'danger',
	Info = 'info',
}

function GetToastClassList(type: ToastType) {
	return `bg-light text-dark border-${type}`;
}

const DEFAULT_DELAY = 5000;

@Injectable({ providedIn: 'root' })
export class ToastService {
	toasts: Toast[] = [];

	success(message: string, actionLabel?: string, action?: Function) {
		this.toasts.push({
			type: ToastType.Success,
			title: 'Tudo certo!',
			icon: 'check-circle-fill',
			message,
			action,
			actionLabel,
			delay: DEFAULT_DELAY,
			classList: GetToastClassList(ToastType.Success),
		});
	}

	danger(message: string, actionLabel?: string, action?: Function) {
		this.toasts.push({
			type: ToastType.Danger,
			title: 'Algo deu errado...',
			icon: 'exclamation-circle-fill',
			message,
			action,
			actionLabel,
			delay: DEFAULT_DELAY * 2,
			classList: GetToastClassList(ToastType.Danger),
		});
	}

	warning(message: string, actionLabel?: string, action?: Function) {
		this.toasts.push({
			type: ToastType.Warning,
			title: 'Atenção',
			icon: 'exclamation-triangle-fill',
			message,
			action,
			actionLabel,
			delay: DEFAULT_DELAY,
			classList: GetToastClassList(ToastType.Warning),
		});
	}

	info(message: string, actionLabel?: string, action?: Function) {
		this.toasts.push({
			type: ToastType.Info,
			title: 'Informação',
			icon: 'info-circle-fill',
			message,
			action,
			actionLabel,
			delay: DEFAULT_DELAY,
			classList: GetToastClassList(ToastType.Info),
		});
	}

	remove(toast: Toast) {
		this.toasts = this.toasts.filter(t => t !== toast);
	}

	clear() {
		this.toasts.splice(0, this.toasts.length);
	}
}
