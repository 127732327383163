import Cookies from 'js-cookie';

import { isObject, isObjectNullOrEmpty } from '@lib-core/helpers/object.helper';
import { isString, isStringNullOrEmpty } from '@lib-core/helpers/string.helper';

const DEFAULT_PATH = '/';
const DEFAULT_EXPIRES = 1 / 12;

function getCookie(name: string): any {
	const rawCookie = Cookies.get(name);

	let cookie = rawCookie;

	if (isStringNullOrEmpty(cookie)) {
		return null;
	}

	try {
		return JSON.parse(cookie);
	} catch (e) {
		return cookie;
	}
}

function setCookie(name: string, value: any): void {
	let data = value;

	clearCookie(name);

	if (!validate(value)) {
		return;
	}

	if (isObject(value)) {
		data = JSON.stringify(value);
	}

	internalSetCookie(name, data);
}

function clearCookie(name: string): void {
	Cookies.remove(name);
}

function clearAllCookies(): void {
	const cookies = Cookies.get();
	Object.keys(cookies).forEach((cookie) => clearCookie(cookie));
}

function validate(value: any): boolean {
	if (isObject(value) && isObjectNullOrEmpty(value)) return false;
	if (isString(value) && isStringNullOrEmpty(value)) return false;
	return true;
}

function internalSetCookie(name: string, data: any): void {
	const internalName = name;
	const internalData = data;
	Cookies.set(internalName, internalData, {
		path: DEFAULT_PATH,
		expires: DEFAULT_EXPIRES,
	});
}

export { getCookie as get, setCookie as set, clearCookie as clear, clearAllCookies as clearAll };
