import { CommonModule } from '@angular/common';
import { OnChanges, Component, Input, SimpleChanges } from '@angular/core';
import { CartItemStatusType } from '@lib-core/enums';
import { capitalize } from '@lib-core/helpers';
import { CartModel, ColorModel } from '@lib-core/models';
import { CurrencyBrlPipe } from '@lib-core/pipes';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-cart-product-item-table',
	standalone: true,
	imports: [CommonModule, CurrencyBrlPipe, NgbTooltipModule],
	templateUrl: './cart-product-item-table.component.html',
})
export class CartProductItemTableComponent implements OnChanges {
	totalNetPrice = 0;

	sizeMap = new Map<string, number>();
	colorMap = new Map<string, number>();
	warningMap = new Map<string, string>();
	dangerMap = new Map<string, string>();
	quantityMap = new Map<string, number>();
	netPriceMap = new Map<string, number>();

	@Input()
	cart: CartModel;

	@Input()
	productCode: string;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['cart']) {
			this.createMaps();
			this.getTotalNetPrice();
		}
	}

	get item() {
		return this.cart.cartItemList.find(item => item.productCode === this.productCode);
	}

	get sizeList(): string[] {
		return this.item.productDetails?.sizes?.map(size => size.size) ?? [];
	}

	get colorList(): ColorModel[] {
		return this.item.productDetails?.colors ?? [];
	}

	get isValid(): boolean {
		return this.item?.productDetails != null;
	}

	createMaps() {
		this.sizeMap = new Map<string, number>();
		this.colorMap = new Map<string, number>();
		this.warningMap = new Map<string, string>();
		this.dangerMap = new Map<string, string>();
		this.quantityMap = new Map<string, number>();
		this.netPriceMap = new Map<string, number>();

		this.cart.cartItemList.forEach(item => {
			if (item.productCode === this.item.productDetails?.code) {
				const grade = this.getGrade(item.colorCode, item.sizeCode);

				this.quantityMap.set(grade, item.quantity);

				this.netPriceMap.set(grade, item.netValue * item.quantity);

				if (item.status === CartItemStatusType.Warning && item.message) {
					this.warningMap.set(grade, item.message);
				}
				if (item.status === CartItemStatusType.Error && item.message) {
					this.dangerMap.set(grade, item.message);
				}
			}
		});

		this.sizeList.forEach(size => {
			this.cart.cartItemList.forEach(item => {
				if (item.sizeCode === size && item.productCode === this.item.productDetails.code) {
					this.sizeMap.set(size, (this.sizeMap.get(size) ?? 0) + item.quantity);
				}
			});
		});

		this.colorList.forEach(color => {
			this.cart.cartItemList.forEach(item => {
				if (item.colorCode === color.code && item.productCode === this.item.productDetails.code) {
					this.colorMap.set(color.code, (this.colorMap.get(color.code) ?? 0) + item.quantity);
				}
			});
		});
	}

	getGrade(color: string, size: string): string {
		return `${color}-${size}`;
	}

	getTotalNetPrice(): void {
		this.totalNetPrice = Array.from(this.netPriceMap.values()).reduce((acc, value) => acc + value, 0);
	}

	getColorLabel(color: ColorModel): string {
		return capitalize(`${color.code} - ${color.description}`);
	}

	getQuantity(color: string, size: string): string {
		return this.quantityMap.get(`${color}-${size}`)?.toString() ?? '';
	}

	getWarning(color: string, size: string): string {
		return this.warningMap.get(`${color}-${size}`) ?? '';
	}

	getTdStatusClass(color: string, size: string): string {
		if (this.dangerMap.get(`${color}-${size}`) != null) {
			return 'table-danger border-0';
		}

		if (this.warningMap.get(`${color}-${size}`) != null) {
			return 'table-warning border-0';
		}
		return '';
	}

	getError(color: string, size: string): string {
		return this.dangerMap.get(`${color}-${size}`) ?? '';
	}

	isColorValid(color: string): boolean {
		return this.colorMap.get(color) > 0;
	}

	getTotalQuantity(size: string): number {
		return this.sizeMap.get(size) ?? 0;
	}
}
