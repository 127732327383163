import { Directive, Input, OnInit, OnDestroy } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[pageClassList]',
})
export class PageClassListDirective implements OnInit, OnDestroy {
	classList: string[];

	ngOnInit(): void {
		this.classList.forEach(className => {
			if (className) {
				document.body.classList.add(className);
			}
		});
	}

	ngOnDestroy(): void {
		this.classList.forEach(className => {
			if (className) {
				document.body.classList.remove(className);
			}
		});
	}

	@Input('pageClassList')
	set pageClassList(value: string) {
		this.classList = value.split(' ');
	}
}
