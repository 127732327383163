import { Injectable } from '@angular/core';
import { HttpResource } from '@lib-core/resources';
import { GalleryRequestDto, GalleryResponseDto } from '@lib-core/dtos';
import { ApiResponseModel, ProductModel, SalesTeamModel, SideFilterModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class GalleryResource {
	private baseUrl = 'Gallery';

	constructor(private httpResource: HttpResource) {}

	query(request: GalleryRequestDto) {
		return this.httpResource.post<ApiResponseModel<GalleryResponseDto>>(`${this.baseUrl}/Query`, request, {
			showLoader: true,
			loaderMessage: 'Buscando grade de produtos',
		});
	}

	getProductDetails(productCode: string, modalityCode: string, salesTeamCode: string, customerCode: string) {
		return this.httpResource.post<ApiResponseModel<ProductModel>>(
			`${this.baseUrl}/GetProduct/${productCode}`,
			{
				modalityCode,
				salesTeamCode,
				customerCode,
			},
			{
				showLoader: true,
				loaderMessage: 'Carregando detalhes do(s) produto(s)',
			},
		);
	}

	listSalesTeam(customerCode: string) {
		return this.httpResource.get<ApiResponseModel<SalesTeamModel[]>>(`${this.baseUrl}/ListSalesTeam/${customerCode}`, {
			showLoader: true,
			loaderMessage: 'Carregando equipe de vendas',
		});
	}
}
