import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

import { ColorModel, ImageModel } from '@lib-core/models';
import { ProductImageComponent } from '@proj-b2b/app/components/product-image/product-image.component';

@Component({
	selector: 'app-product-carousel',
	templateUrl: './product-carousel.component.html',
	styleUrls: ['./product-carousel.component.scss'],
	imports: [CommonModule, ProductImageComponent],
	standalone: true,
})
export class ProductCarouselComponent {
	@Input()
	imgList: ImageModel[] = [];

	@Input()
	selectedColor: ColorModel;

	@Input()
	colorList: ColorModel[] = [];

	@Input()
	height: string | number = 300;

	filteredImgList: ImageModel[] = [];

	@Output()
	onImageClick = new EventEmitter<ImageModel>();

	get carouselHeight(): string {
		return typeof this.height === 'string' ? this.height : `${this.height}px`;
	}

	get currentImage() {
		return this.filteredImgList?.length ? this.filteredImgList[0] : null;
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('imgList' in changes || 'selectedColor' in changes) {
			this.updateFilteredImgList();
		}
	}

	private updateFilteredImgList() {
		this.filteredImgList = this.selectedColor
			? this.imgList.filter((img) => img.color === this.selectedColor.code)
			: this.imgList;
	}

	triggerOnImageClick(image: ImageModel) {
		this.onImageClick.emit(image);
	}
}
