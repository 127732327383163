export class SalesOrderResponseModel {
	additionalProperties: {} = {};
	e_ORDEM_VENDA: SalesOrderModel[] = [];
}

export class SalesOrderModel {
	cD_DOCUMENTO: string = '';
	cD_REFERENCIA: string | null = null;
	cD_ORGANIZACAO: string = '';
	cD_CANAL: string = '';
	cD_SETOR: string = '';
	cD_ESCRITORIO: string = '';
	cD_EQUIPE: string = '';
	cD_CLIENTE: string = '';
	cD_REPRESENTANTE: string = '';
	cD_VENDEDOR: string = '';
	cD_CONSULTOR: string | null = null;
	cD_TIPO_DOCUMENTO: string = '';
	cD_TIPO_PEDIDO: string | null = null;
	cD_CONDICAO_PGTO: string = '';
	cD_PRAZO_EXTRA: string | null = null;
	pedidO_CLIENTE: string | null = null;
	cD_PERIODO_VENDA: string = '';
	dT_CLIENTE: string | null = null;
	dT_ENTRADA: string = '';
	dT_REMESSA: string = '';
	dT_FATURAMENTO: string = '';
	qtD_ORDEM_VENDA: number = 0;
	vlR_ORDEM_VENDA: number = 0;
	cD_MODALIDADE: string | null = null;
	cD_STATUS: string = '';
	cD_CANCELADO: string | null = null;
	redE_PGTO_PERMITIDO: string | null = null;
	redE_PGTO_REALIZADO: string | null = null;
	descontos: string = '';
	observacao: string | null = null;
	cupoM_DESCONTO: string | null = null;
	textO_DANFE: string | null = null;
	cD_SHOWROOM: string | null = null;
	dS_SHOWROOM: string | null = null;
	pracA_SHOWROOM: string | null = null;
	cD_PEMS: string | null = null;
	cD_PEMS_CANCELADO: string | null = null;
	e_ORDEM_VENDA_SUB_ITEM: any[] = [];
	additionalProperties: {
		NM_CLIENTE: string;
		NM_REPRESENTANTE: string;
		NM_VENDEDOR: string;
	};
}
