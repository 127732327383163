import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { inject, Injectable } from '@angular/core';

const I18N_VALUES: any = {
	ptBr: {
		weekdays: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
		months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		weekLabel: 'sem',
	},
};

@Injectable()
export class I18n {
	language = 'ptBr';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
	private _i18n = inject(I18n);

	getWeekdayLabel(weekday: number): string {
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}

	override getWeekLabel(): string {
		return I18N_VALUES[this._i18n.language].weekLabel;
	}

	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
	}

	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
	readonly DELIMITER = '/';

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		if (!date?.day || !date?.month || !date?.year) {
			return '';
		}
		const day = date.day.toString().padStart(2, '0');
		const month = date.month.toString().padStart(2, '0');
		return date ? day + this.DELIMITER + month + this.DELIMITER + date.year : '';
	}
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = '/';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		if (!date?.day || !date?.month || !date?.year) {
			return '';
		}
		const day = date.day.toString().padStart(2, '0');
		const month = date.month.toString().padStart(2, '0');
		return date ? day + this.DELIMITER + month + this.DELIMITER + date.year : '';
	}
}
