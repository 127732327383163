import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { scrollToTop } from '@lib-core/helpers';
import { ENVIRONMENT } from '../../../../../../../../environments/environment';

@Component({
	selector: 'app-scroll-to-top',
	standalone: true,
	templateUrl: './scroll-to-top.html',
	styleUrls: ['./scroll-to-top.scss'],
	imports: [CommonModule],
})
export class ScrollToTopButton {
	isVisible = false;
	buttonScrollToTopActive = ENVIRONMENT.backOfficeSettings.enableScrollToTopButton;

	@HostListener('window:scroll', ['$event'])
	onWindowScroll() {
		const threshold = 100;
		const currentScrollPosition = window.scrollY;
		this.isVisible = currentScrollPosition > threshold;
	}

	goToTop() {
		scrollToTop();
	}
}
