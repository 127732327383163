import { Injectable } from '@angular/core';
import { ModalityModel } from '@lib-core/models';
import { BaseListStore } from '@lib-core/stores/base-list.store';

import { MODALITY_COOKIE_NAME, MODALITY_LIST_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class ModalityStore extends BaseListStore<ModalityModel> {
	constructor() {
		super(MODALITY_COOKIE_NAME, MODALITY_LIST_COOKIE_NAME);
	}

	isProgramado(): boolean {
		return this.get()?.code === 'PROGRAMADO';
	}
}
