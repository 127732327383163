import { Component, Input } from '@angular/core';
import { RouterService } from '@lib-core/services';

@Component({
	standalone: true,
	selector: 'app-cart-ordered-successfully',
	imports: [],
	templateUrl: './cart-ordered-successfully.component.html',
})
export class CartOrderedSuccessfullyComponent {
	constructor(private routerService: RouterService) {}

	@Input()
	erpSalesOrderCode: string = null;

	goToMyOrders() {
		this.routerService.goToMyOrders();
	}
}
