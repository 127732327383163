import { Injectable } from '@angular/core';
import { BaseStore } from '@lib-core/stores/base.store';
import { USER_DATA_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class UserStore {
	private userData = new BaseStore<string>(USER_DATA_COOKIE_NAME, false);

	setUsername(username: string) {
		return this.userData.set(username);
	}

	getUsername() {
		return this.userData.get();
	}
}
