import { CartStatusType } from '@lib-core/enums';
import { AppliedDiscountsModel, CartItemModel } from '@lib-core/models';

export class CartModel {
	id: string;
	name: string;
	description: string;
	username: string;
	customerCode: string;
	representativeCode: string;
	salesPeriodCode: string;
	futureField1: string;
	futureField2: string;
	paymentConditionCode: string;
	updateDate: string;
	insertDate: string;
	cartItemList: CartItemModel[];
	status: CartStatusType;
	modalityCode: string;
	erpSalesOrderCode: string;
	commercialRuleGuid: string;
	salesTeamCode: string;
	numberOfAdditionalDaysForPayment: number;
	appliedDiscountList: AppliedDiscountsModel[];
	netValue: number;
	message: string;
	grossValue: number;
	integrationErrors: any[];

	constructor(data: any) {
		this.id = data?.id;
		this.name = data?.name;
		this.description = data?.description;
		this.username = data?.username;
		this.customerCode = data?.customerCode;
		this.representativeCode = data?.representativeCode;
		this.salesPeriodCode = data?.salesPeriodCode;
		this.futureField1 = data?.futureField1;
		this.futureField2 = data?.futureField2;
		this.paymentConditionCode = data?.paymentConditionCode;
		this.updateDate = data?.updateDate;
		this.insertDate = data?.insertDate;
		this.cartItemList = data?.cartItemList;
		this.status = data?.status;
		this.modalityCode = data?.modalityCode;
		this.erpSalesOrderCode = data?.erpSalesOrderCode;
		this.commercialRuleGuid = data?.commercialRuleGuid;
		this.salesTeamCode = data?.salesTeamCode;
		this.numberOfAdditionalDaysForPayment = data?.numberOfAdditionalDaysForPayment;
		this.appliedDiscountList = data?.appliedDiscountList;
		this.netValue = data?.netValue;
		this.message = data?.message;
		this.grossValue = data?.grossValue;
		this.integrationErrors = data?.integrationErrors;
	}

	get distinctCartItemList() {
		if (this?.cartItemList == null || this?.cartItemList?.length === 0) {
			return [];
		}
		return this?.cartItemList?.filter((item, index, self) => {
			return index === self.findIndex(t => t.productCode === item.productCode);
		});
	}
}
