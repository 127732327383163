import { Config, ConfigColumnDefs, Order } from 'datatables.net';

import DataTablesPtBr from '../../assets/i18n/datatables/pt-br.json';

export function getDataTableOptions(): Config {
	return {
		language: DataTablesPtBr,
		info: true,
		pagingType: 'simple_numbers',
		scrollCollapse: true,
		layout: {
			topEnd: {
				search: {
					placeholder: 'Pesquisar',
				},
			},
		},
		searchDelay: 300,
	};
}

export function getDataTableOrderByIndex(index: number = 0, direction: 'asc' | 'desc' = 'asc'): any {
	return { order: [index, direction] };
}

export function getNotOrderableColumns(columns: number[]): any {
	return { columnDefs: [{ orderable: false, targets: columns }] };
}
