import { Injectable } from '@angular/core';
import { BaseStore } from '@lib-core/stores';
import { EBOOK_IMPORT_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class EbookImportStore extends BaseStore<any> {
	constructor() {
		super(EBOOK_IMPORT_COOKIE_NAME, false);
	}
}
