<shared-page-title />
<div *ngIf="customerList">
	<table datatable [dtOptions]="dtOptions" aria-describedby="tabela-devoluções" class="table table-striped w-100">
		<thead>
			<tr>
				<th class="col-3 text-center" scope="col">CNPJ</th>
				<th class="col-13 text-center" scope="col">Nome</th>
				<th class="col-2 text-center" scope="col">Ações</th>
			</tr>
		</thead>
		<tbody class="table-group-divider">
			@for (customer of customerList; track customer.code) {
			<tr class="align-middle" *ngIf="customer.code">
				<td class="text-center">{{ customer.cnpj | cpfCnpj }}</td>
				<td>{{ customer.name }}</td>
				<td class="text-center">
					<button type="button" class="btn btn-outline-primary" (click)="openCustomerDetails(customer)">Detalhes</button>
				</td>
			</tr>
			}
		</tbody>
	</table>

	<ng-template #customerDetailsModal let-modal>
		<div class="modal-header">
			<h4 class="modal-title" id="modal-basic-title">Detalhes do cliente</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
		</div>
		<div class="modal-body">
			<div core-group-box classList="mb-4">
				<ng-template #header>Informação de Cadastro</ng-template>
				<ng-template #body>
					<div core-label-value class="col-24 col-xxl-4" label="CNPJ" [value]="selectedCustomer.cnpj" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Nome Fantasia" [value]="selectedCustomer.name" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Razão Social" [value]="selectedCustomer.name" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="E-mail" [value]="selectedCustomer.email" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Telefone" [value]="selectedCustomer.telephone" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Data Cadastro" [value]="selectedCustomer.foundationDate" [colon]="true" [singleLine]="true"></div>
				</ng-template>
			</div>

			<div core-group-box>
				<ng-template #header>Endereço</ng-template>
				<ng-template #body>
					<div core-label-value label="CEP" [value]="selectedCustomer.zipCode" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Endereço" [value]="selectedCustomer.street" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Bairro" [value]="selectedCustomer.neighborhood" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Cidade" [value]="selectedCustomer.municipality" [colon]="true" [singleLine]="true"></div>
					<div core-label-value label="Estado" [value]="selectedCustomer.state" [colon]="true" [singleLine]="true"></div>
				</ng-template>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-primary" (click)="modal.dismiss()">Pronto</button>
		</div>
	</ng-template>
</div>
