<!-- COMPONENTE SERÁ EXCLUÍDO QUANDO VIRAR 100% DO PORTAL -->
<div class="content" [ngClass]="{ show: isVisible }">
	<div class="position-absolute end-0">
		<button class="btn btn-sm text-secondary" (click)="close()">
			<i class="bi bi-x-lg"></i>
		</button>
	</div>

	<div class="px-3 py-5 text-center">
		<img alt="Malwee" src="./assets/images/logo.png" width="200" />
	</div>
	<hr class="m-0" />

	<div class="p-3" *ngIf="useMenuOnSidebar && isOnGalleryRoute">
		<div class="pb-3">
			<core-gallery-filter-modality />
		</div>
		<div class="pb-3">
			<core-gallery-filter-sales-team />
		</div>
		<div class="pb-3">
			<core-gallery-filters />
		</div>
	</div>

	<div class="position-absolute bottom-0 w-100">
		<hr class="m-0" />
		<div class="text-center py-3">
			<a [href]="portalUrl" target="_self" class="link-secondary">Voltar para o portal</a>
		</div>
	</div>
</div>

<div class="overlay" [ngClass]="{ show: isVisible }" (click)="close()" (keyup)="close()"></div>
