<nav class="navbar navbar-expand-lg position-sticky top-0 p-0 bg-light z-3 shadow-sm">
	<div class="container-xxl">
		<button class="btn btn-sm text-secondary" (click)="toggleSidebar()" *ngIf="enableSidebar">
			<i class="bi bi-list fs-3"></i>
		</button>

		<a class="navbar-brand" href="/">
			<img alt="Malwee" src="./assets/images/logo.png" class="d-inline-block align-text-top" (click)="clearFilters()" (keyup)="clearFilters()" />
		</a>
		<button
			class="btn btn-sm text-secondary d-lg-none"
			type="button"
			data-bs-toggle="collapse"
			data-bs-target="#navbarNav"
			aria-controls="navbarNav"
			aria-expanded="false"
			aria-label="Toggle navigation">
			<i class="bi bi-person-circle fs-4"></i>
		</button>
		<div class="collapse navbar-collapse" id="navbarNav">
			<ul class="navbar-nav ms-auto">
				<li class="nav-item d-flex align-items-center" *ngIf="!isRepresentative">
					<a class="nav-link d-flex align-items-center">
						<i class="bi fs-4 m-1" [class]="customerIcon"></i>
						<span>{{ customerLabel }}</span>
					</a>
				</li>
				<li class="nav-item d-flex align-items-center" *ngIf="isRepresentative">
					<a
						class="nav-link d-flex align-items-center cursor--pointer"
						[class.pe-0]="showRemoveCustomerButton"
						(click)="selectCustomer()"
						[ngbTooltip]="customerTooltip">
						<i class="bi fs-4 m-1" [class]="customerIcon"></i>
						<span>{{ customerLabel }}</span>
					</a>
					<a
						*ngIf="showRemoveCustomerButton"
						(click)="removeCustomer()"
						ngbTooltip="Remover seleção de cliente"
						class="text-decoration-none cursor--pointer p-2">
						{{ closeUnicode }}
					</a>
				</li>
				<li class="nav-item" *ngIf="enableCart">
					<a class="nav-link d-flex align-items-center cursor--pointer" [routerLink]="cartRoute">
						<i class="bi bi-handbag fs-4 m-1"></i>
						<span>{{ cartCount }}</span>
					</a>
				</li>
				<li class="nav-item" *ngIf="enableMyAccount">
					<a class="nav-link d-flex align-items-center cursor--pointer" [routerLink]="accountRoute">
						<i class="bi bi-person fs-4 m-1"></i>
						<span>Minha Conta</span>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link d-flex align-items-center cursor--pointer" [routerLink]="authRoutes.logout.url">
						<i class="bi bi-arrow-bar-right fs-4 m-1"></i>
						<span>Sair</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</nav>

<ng-template #customerListModal let-modal>
	<shared-customer-select-modal />
</ng-template>
