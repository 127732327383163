export const PRODUCT_PATH = 'produto';
export const PRODUCT_ROUTE = `/${PRODUCT_PATH}`;

export const PRODUCT_ROUTE_NAMES = Object.freeze({
	detail: {
		path: ':id',
		url: `${PRODUCT_ROUTE}/:id`,
		title: 'Detalhes do produto',
	},
});
