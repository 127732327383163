export const CART_PATH = 'sacola';
export const CART_ROUTE = `/${CART_PATH}`;

export const CART_ROUTE_NAMES = Object.freeze({
	details: {
		path: 'detalhes',
		url: `${CART_ROUTE}/detalhes`,
		title: 'Sacola',
	},
});
