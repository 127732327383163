import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonModule, NgTemplateOutlet } from '@angular/common';

import { Toast, ToastService } from '@lib-core/services';
@Component({
	selector: 'core-toast-container',
	standalone: true,
	imports: [NgbToastModule, NgTemplateOutlet, CommonModule],
	template: `
		@for (toast of toastService.toasts; track toast) {
		<ngb-toast [class]="toast.classList" [autohide]="true" [delay]="toast.delay || 3000" (hidden)="toastService.remove(toast)">
			<div class="d-flex">
				<div class="d-flex align-items-center ps-1 pe-3">
					<i class="bi bi-{{ toast.icon }} fs-5 text-{{ toast.type }}"></i>
				</div>
				<div>
					<p class="mb-1">
						<strong>{{ toast.title }}</strong>
					</p>
					<p class="text-break mb-0" [ngClass]="{ 'mb-2': toast.actionLabel }" [innerHTML]="toast.message"></p>
					<button *ngIf="toast.actionLabel" class="btn btn-sm btn-primary" (click)="onActionClick(toast)">
						{{ toast.actionLabel }}
					</button>
				</div>
			</div>
		</ngb-toast>
		}
	`,
	host: { class: 'toast-container position-fixed end-0 p-3', style: 'z-index: 1200' },
})
export class ToastsContainer {
	toastService = inject(ToastService);

	constructor(private router: Router) {
		router.events.subscribe(val => {
			this.clearOnNavigation(val);
		});
	}

	onActionClick(toast: Toast) {
		toast.action();
		this.toastService.remove(toast);
	}

	clearOnNavigation(val: any) {
		if (val instanceof NavigationEnd) {
			this.toastService.clear();
		}
	}
}
