<div class="d-contents" *ngIf="showValue">
	<div
		class="d-flex mb-2"
		*ngIf="!useInput"
        [class]="classList"
		[ngClass]="{ 'flex-column': !singleLine, 'align-items-center': singleLine }"
	>
		<span class="form-label fw-medium" [class]="labelClassList" [ngClass]="{ 'mb-0': singleLine }">{{ label }}<span *ngIf="colon">:</span>&nbsp;</span>
		<span class="form-label" [class]="valueClassList" [ngClass]="{ 'mb-0': singleLine }">{{ value }}</span>
	</div>

	<div
		class="d-flex mb-2"
		*ngIf="useInput"
        [class]="classList"
		[ngClass]="{ 'flex-column': !singleLine, 'align-items-center': singleLine }"
	>
		<label [for]="label" class="form-label d-inline" [class]="labelClassList" [ngClass]="{ 'mb-0 me-2': singleLine }">{{
			label
		}}</label>
		<input
			type="input"
			class="form-control d-inline-block"
			[class]="valueClassList"
			[name]="label"
			[value]="value"
			disabled
			readonly
		/>
	</div>
</div>
