import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { formatDateAndTime } from '@lib-core/helpers';

@Pipe({
	name: 'longDate',
	standalone: true,
})
export class DateLongPipe extends DatePipe implements PipeTransform {
	override transform(value: Date): any {
		return formatDateAndTime(value);
	}
}
