import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';

import { NavbarComponent } from '@lib-shared/components';

@Component({
	standalone: true,
	selector: 'app-product',
	imports: [RouterOutlet, CommonModule, RouterModule, NavbarComponent],
	templateUrl: './product-wrapper.component.html',
})
export class ProductWrapperComponent {}
