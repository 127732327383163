import { Injectable } from '@angular/core';

import { AuthModel } from '@lib-core/models';
import { BaseStore } from '@lib-core/stores';
import { AUTH_DATA_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class AuthStore extends BaseStore<AuthModel> {
	constructor() {
		super(AUTH_DATA_COOKIE_NAME);
	}
}
