<div class="color-sample bg-light border" [class]="classList">
	<div class="color-sample__container" (click)="onClick()" (keypress)="onClick()">
		<img
			[class]="internalClassList"
			class="color-sample__image"
			*ngIf="sampleUrl"
			[src]="sampleUrl"
			alt="sampleUrl"
		/>
		<div
			[class]="internalClassList"
			class="color-sample__image"
			*ngIf="!sampleUrl"
			[style.background-color]="rgb"
		></div>
	</div>
</div>
