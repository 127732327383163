import { CommonModule } from '@angular/common';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ColorModel } from '@lib-core/models';
import { ColorSampleComponent } from '@proj-b2b/app/components/color-sample/color-sample.component';

@Component({
	standalone: true,
	selector: 'app-color-list-sample',
	imports: [CommonModule, ColorSampleComponent, NgbCarousel],
	templateUrl: './color-sample-list.component.html',
})
export class ColorSampleListComponent {
	@Input()
	colorList: ColorModel[] = [];

	@Output()
	colorSelected = new EventEmitter<ColorModel>();

	classList: string = 'color-sample-list';

	selectColor(color: ColorModel) {
		this.colorSelected.emit(color);
	}
}
