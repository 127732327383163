<section class="h-100">
	<div class="container-xxl h-100">
		<div class="row justify-content-sm-center h-100">
			<div class="col-24 col-sm-20 col-md-16 col-lg-12 col-xl-10 col-xxl-9">
				<div class="text-center my-5">
					<img src="./assets/images/logo.png" alt="logo" />
				</div>
				<div class="card shadow-sm">
					<div class="card-body p-5">
						<h1 class="fs-4 card-title fw-bold mb-4 text-center">Autenticação externa</h1>
						<div class="alert alert-danger mb-0" role="alert" *ngIf="error">
							<p class="mb-0" [innerHTML]="error"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
