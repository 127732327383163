<div class="d-contents" *ngIf="filter">

	<div class="col-24 mb-3" [class.d-none]="!filterViewerCount">
		<div class="d-flex justify-content-between align-items-center mb-2">
			<label for="none" class="form-label fw-medium mb-0">Filtros aplicados</label>
			<small class="text-primary text-decoration-underline">
				<a href="javascript:void(0)" (click)="clear()">limpar</a>
			</small>
		</div>
		<app-gallery-filter-viewer (change)="updateFilterViewerCount($event)" />
	</div>

	<div class="col-24" *ngIf="filter.collections">
		<app-gallery-filter-group
			label="Coleções"
			[value]="request.collections"
			(onChange)="onFilterGroupChange('collections', $event)"
			[entityList]="filter.collections"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.seasons">
		<app-gallery-filter-group
			label="Estações"
			[value]="request.seasons"
			(onChange)="onFilterGroupChange('seasons', $event)"
			[entityList]="filter.seasons"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.categories">
		<app-gallery-filter-group
			label="Categoria"
			[value]="request.categories"
			(onChange)="onFilterGroupChange('categories', $event)"
			[entityList]="filter.categories"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.segments">
		<app-gallery-filter-group
			label="Segmento"
			[value]="request.segments"
			(onChange)="onFilterGroupChange('segments', $event)"
			[entityList]="filter.segments"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.genders">
		<app-gallery-filter-group
			label="Gênero"
			[value]="request.genders"
			(onChange)="onFilterGroupChange('genders', $event)"
			[entityList]="filter.genders"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.species">
		<app-gallery-filter-group
			label="Espécie"
			[value]="request.species"
			(onChange)="onFilterGroupChange('species', $event)"
			[entityList]="filter.species"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.brands">
		<app-gallery-filter-group
			label="Marca"
			[value]="request.brands"
			(onChange)="onFilterGroupChange('brands', $event)"
			[entityList]="filter.brands"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.sizes">
		<app-gallery-filter-group
			label="Tamanho"
			[value]="request.sizes"
			[capitalize]="false"
			(onChange)="onFilterGroupChange('sizes', $event)"
			[entityList]="sizeList"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.productGroups">
		<app-gallery-filter-group
			label="Grupo Produto"
			[value]="request.productGroups"
			(onChange)="onFilterGroupChange('productGroups', $event)"
			[entityList]="filter.productGroups"></app-gallery-filter-group>
	</div>

	<div class="col-24" *ngIf="filter.colors">
		<app-gallery-filter-group
			label="Cor"
			[hasSample]="true"
			[showCode]="true"
			[value]="request.colors"
			(onChange)="onFilterGroupChange('colors', $event)"
			[entityList]="filter.colors"></app-gallery-filter-group>
	</div>
</div>
