import { PagedModel } from '@lib-core/models';

export class EbookRequestDto extends PagedModel {
	public customerCode: string = '';
	public representativeCode: string = '';

	constructor() {
		super();
	}
}
