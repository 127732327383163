const LOCALE = 'pt-BR';
const TIME_ZONE_AMERICA = 'America/Sao_Paulo';

function isValidDate(date: any) {
	return date instanceof Date && !isNaN(date.getTime());
}

function formatDate(date: any) {
	if (!isValidDate(date)) {
		date = new Date(date);
	}
	return date.toLocaleDateString(LOCALE, {
		timeZone: TIME_ZONE_AMERICA,
	});
}

function formatTime(date: Date) {
	if (!isValidDate(date)) {
		date = new Date(date);
	}
	return date.toLocaleTimeString(LOCALE, {
		timeZone: TIME_ZONE_AMERICA,
	});
}

function formatDateAndTime(date: Date) {
	return `${formatDate(date)} ${formatTime(date)}`;
}

export { isValidDate, formatDate, formatTime, formatDateAndTime };
