<div [class]="classList" class="position-relative w-100 py-0" [style.padding]="buttonWidth" *ngIf="hasImages">
	<button class="position-absolute h-100 top-50 start-0 translate-middle-y bg-transparent border-0" [style.width]="buttonWidth" (click)="scrollLeft()">
		{{ prevIcon }}
	</button>
	<div [class]="internalClassList" #widgetsContent>
		<app-product-image
			*ngFor="let imageUrl of imageList"
			[url]="imageUrl.url"
			[height]="thumbnailHeight"
			(click)="onImageClick(imageUrl?.url)"
			(keypress)="onImageClick(imageUrl?.url)"></app-product-image>
	</div>
	<button class="position-absolute h-100 top-50 end-0 translate-middle-y bg-transparent border-0" [style.width]="buttonWidth" (click)="scrollRight()">
		{{ nextIcon }}
	</button>
</div>
