export class ApiResponseModel<T> {
	data: T;
	message: string;
	success: boolean;

	constructor(data: T, success = true, message = '') {
		this.data = data;
		this.success = success;
		this.message = message;
	}
}
