import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { GalleryEbookComponent } from '@proj-b2b/app/modules/gallery/components/gallery-ebook/gallery-ebook.component';
import { GALLERY_ROUTE_NAMES } from '@proj-b2b/app/modules/gallery/gallery.constants';

@Component({
	standalone: true,
	selector: 'app-cart-empty',
	imports: [RouterLink, GalleryEbookComponent],
	templateUrl: './cart-empty.component.html',
})
export class CartEmptyComponent {
	galleryRoutes = GALLERY_ROUTE_NAMES;
}
