import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RouterService {
	constructor(private router: Router) {}

	reload() {
		window.location.reload();
	}

	goBack() {
		(history as any).back();
	}

	getCurrentUrl() {
		return this.router.url;
	}

	getGuards(path: string) {
		const paths = path?.replace(/^\/+/g, '')?.split('/');

		if (paths.length === 0) return null;

		const route = this.router.config.find(route => route.path === paths[0]);

		if (paths.length === 1) return route?.canActivate;

		return route?.children?.find(child => child.path === paths[1])?.canActivate;
	}

	isPathNeedsGuard(path: string, guard: string) {
		const guards = this.getGuards(path);
		return guards?.some(g => g.name === guard);
	}

	goToMyOrders() {
		this.router.navigate(['/minha-conta/pedidos']);
	}

	goToProduct(productCode) {
		this.router.navigate(['/produto', productCode]);
	}
}
