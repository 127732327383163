import { NgTemplateOutlet } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-custom-collapse',
	imports: [NgTemplateOutlet, NgbAccordionModule],
	templateUrl: './custom-collapse.component.html',
	styleUrl: './custom-collapse.component.scss',
})
export class CustomCollapseComponent {
	@ContentChild('header')
	headerTemplate: TemplateRef<any>;

	@ContentChild('body')
	bodyTemplate: TemplateRef<any>;

	@Input()
	collapsed: boolean;

	@Input()
	classList: string;

	@Input()
	buttonClassList: string;

	@Input()
	bodyClassList: string;
}
