<ng-container *ngIf="filteredImgList.length > 0; else noImages">
	<app-product-image
		[url]="currentImage.url"
		[height]="carouselHeight"
		classList="cursor--pointer"
		(click)="triggerOnImageClick(currentImage)"
		(keydown.enter)="triggerOnImageClick(currentImage)"
	/>
</ng-container>

<ng-template #noImages>
	<app-product-image
		classList="cursor--pointer"
		[height]="carouselHeight"
		(click)="triggerOnImageClick(null)"
		(keydown.enter)="triggerOnImageClick(null)"
	/>
</ng-template>
