import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild, effect } from '@angular/core';

import { CART_ROUTE_NAMES } from '@proj-b2b/app/modules/cart/cart.constants';
import { ACCOUNT_ROUTE_NAMES } from '@lib-shared/modules/account';
import { GALLERY_EBOOK_MAX_PRODUCT_COUNT } from '@lib-core/constants';

import { CUSTOMER_ROUTE_NAMES } from '@lib-shared/modules/customer';
import { GalleryRequestDto, GalleryResponseDto } from '@lib-core/dtos';
import { EBookService, RouterService, ToastService } from '@lib-core/services';
import { CartCountStore, CustomerStore, EbookImportStore, GalleryRequestStore, GalleryResponseStore, ModalityStore } from '@lib-core/stores';
import { ApiResponseModel } from '@lib-core/models';
import { MIMEType } from '@lib-core/enums';

@Component({
	standalone: true,
	selector: 'app-gallery-ebook',
	imports: [CommonModule],
	templateUrl: './gallery-ebook.component.html',
})
export class GalleryEbookComponent implements AfterViewInit {
	request: GalleryRequestDto = null;
	response: GalleryResponseDto = null;
	maxEbookCount = GALLERY_EBOOK_MAX_PRODUCT_COUNT;

	@Input()
	hideGenerate: boolean = false;

	@Input()
	hideImport: boolean = false;

	@Input()
	hideGoToEbooks: boolean = false;

	@Input()
	importButtonClassList: string = 'w-100';

	@ViewChild('input', { static: false })
	input: ElementRef;

	@ViewChild('importModal')
	importModal: ElementRef;

	@ViewChild('generateModal')
	generateModal: ElementRef;

	@ViewChild('maxCountModal')
	maxCountModal: ElementRef;

	@ViewChild('modalitySwitchModal')
	modalitySwitchModal: ElementRef;

	@ViewChild('modalitySwitchErrorModal')
	modalitySwitchErrorModal: ElementRef;

	constructor(
		private router: Router,
		private modalService: NgbModal,
		private toastService: ToastService,
		private ebookService: EBookService,
		private routerService: RouterService,
		private modalityStore: ModalityStore,
		private customerStore: CustomerStore,
		private cartCountStore: CartCountStore,
		private ebookImportStore: EbookImportStore,
		private galleryRequestStore: GalleryRequestStore,
		private galleryResponseStore: GalleryResponseStore,
	) {
		effect(() => {
			this.request = this.galleryRequestStore.get();
		});
	}

	ngAfterViewInit(): void {
		this.importFromState();
	}

	clear() {
		this.input.nativeElement.value = '';
		this.ebookImportStore.clear();
	}

	importFromState(): void {
		const ebook = this.ebookImportStore.get();
		if (ebook) {
			this.confirmImport({
				target: {
					files: [ebook],
				},
			});
		}
	}

	confirmImport(event: any) {
		if (!event.target?.files[0]!) {
			return;
		}
		const file = event.target.files[0];

		if (file.type !== MIMEType.Pdf) {
			this.toastService.danger('Arquivo inválido. Somente arquivos PDF são permitidos.');
			return;
		}

		if (this.customerStore.get() == null) {
			this.ebookImportStore.set(file);
			this.router.navigate([CUSTOMER_ROUTE_NAMES.choose.url]);
			return;
		}

		if (this.cartCountStore.get() == 0) {
			this.import(file);
			return;
		}

		this.modalService.open(this.importModal, { size: 'lg', centered: true }).result.then(
			result => {
				this.import(file, result === 'replace');
			},
			() => {},
		);
	}

	confirmGenerate() {
		this.response = this.galleryResponseStore.get();

		if (this.response.totalCount > GALLERY_EBOOK_MAX_PRODUCT_COUNT) {
			this.modalService.open(this.maxCountModal, { centered: true }).result.then(
				() => {},
				() => {},
			);
			return;
		}

		this.modalService.open(this.generateModal, { size: 'lg', centered: true }).result.then(
			() => {
				if (this.validate()) {
					this.generate();
				}
			},
			() => {},
		);
	}

	validate() {
		const request = this.galleryRequestStore.get();

		if (!request) {
			this.toastService.danger('Erro ao buscar filtros da galeria, tente novamente mais tarde.');
			return false;
		}

		if (!this.response?.products?.length) {
			this.toastService.danger('Nenhum produto encontrado para geração do e-book.');
			return false;
		}

		this.request = request;

		return true;
	}

	generate() {
		this.ebookService.generate(this.request).subscribe(response => {
			if (response.success) {
				const message = 'Solicitação de ebook gerada com sucesso! Clique no botão abaixo para acessar seus ebooks.';
				this.toastService.success(message, 'Ir para meus ebooks', () => this.goToMyEbooks());
				return;
			}
			this.toastService.danger('Erro solicitar a geração do ebook. Tente novamente mais tarde.');
		});
	}

	import(file: File, replace: boolean = true) {
		this.ebookService.import(file, replace).subscribe({
			next: response => {
				if (response.data) {
					this.onImportSuccess();
					return;
				}

				if (this.hasImportFailedByModality(response)) {
					this.onImportErrorByModality(response, file, replace);
					return;
				}

				this.clear();
				this.toastService.danger(response.message);
			},
			error: error => {
				this.clear();
				this.toastService.danger(error);
			},
		});
	}

	onImportSuccess() {
		this.clear();
		const isCartPage = this.routerService.getCurrentUrl().indexOf('sacola') >= 0;

		let message = 'Arquivo importado com sucesso!';

		if (!isCartPage) {
			message += ' Redirecionando para a sacola...';
		}

		this.toastService.success(message);

		if (isCartPage) {
			this.routerService.reload();
			return;
		}
		this.router.navigate([CART_ROUTE_NAMES.details.url]);
	}

	hasImportFailedByModality(response: ApiResponseModel<boolean>) {
		return response.message.indexOf('modalidade') >= 0;
	}

	onImportErrorByModality(response: ApiResponseModel<boolean>, file: File, replace: boolean = false) {
		try {
			if (!replace) {
				this.modalService.open(this.modalitySwitchErrorModal, { centered: true, size: 'lg' });
				this.clear();
				return;
			}

			this.modalService.open(this.modalitySwitchModal, { centered: true, size: 'lg' }).result.then(
				() => {
					var modality = response.message.split('-')[1].trim();

					if (!modality) {
						this.toastService.danger(response.message);
						this.clear();
						return;
					}

					this.modalityStore.setByProp(modality);
					this.import(file, replace);
				},
				() => {
					this.clear();
				},
			);
			return;
		} catch (err) {
			this.toastService.danger(response.message);
		}
	}

	goToMyEbooks() {
		this.router.navigate([ACCOUNT_ROUTE_NAMES.ebooks.url]);
	}
}
