import { Component, effect } from '@angular/core';
import { GalleryRequestDto } from '@lib-core/dtos';
import { GalleryRequestStore } from '@lib-core/stores';

@Component({
	standalone: true,
	selector: 'app-hero',
	imports: [],
	templateUrl: './hero.component.html',
})
export class HeroComponent {
	request: GalleryRequestDto = null;

	constructor(private galleryRequestStore: GalleryRequestStore) {
		effect(() => {
			this.request = this.galleryRequestStore.get();
		});
	}

	get banner() {
		return (
			'./assets/images/banners/' +
			(!this.request?.salesTeamCode ? 'default.png' : this.request.salesTeamCode + '.jpeg')
		);
	}
}
