<shared-page-title />
<div *ngIf="isRepresentative">
	<div class="row">
		<div core-group-box class="col-24">
			<ng-template #header>Informação de Cadastro</ng-template>
			<ng-template #body>
				<div core-label-value label="Código" [value]="representative.code" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Nome" [value]="representative.name" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="E-mail" [value]="representative.email" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="CNPJ" [value]="representative.cnpj" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Celular" [value]="representative.mobileNumber" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Telefone" [value]="representative.phoneNumber" [colon]="true" [singleLine]="true"></div>
			</ng-template>
		</div>
	</div>
</div>

<div *ngIf="isCustomer">
	<div class="row">
		<div core-group-box class="col-24 col-md-12 mb-4">
			<ng-template #header>Informação de Cadastro</ng-template>
			<ng-template #body>
				<div core-label-value label="CNPJ" [value]="customer.cnpj" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Nome Fantasia" [value]="customer.name" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Razão Social" [value]="customer.name" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="E-mail" [value]="customer.email" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Telefone" [value]="customer.telephone" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Data Cadastro" [value]="customer.foundationDate" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Número Pedidos" value="11" [colon]="true" [singleLine]="true"></div>
			</ng-template>
		</div>

		<div core-group-box class="col-24 col-md-12 mb-4">
			<ng-template #header>Endereço</ng-template>
			<ng-template #body>
				<div core-label-value label="CEP" [value]="customer.zipCode" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Endereço" [value]="customer.street" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Bairro" [value]="customer.neighborhood" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Cidade" [value]="customer.municipality" [colon]="true" [singleLine]="true"></div>
				<div core-label-value label="Estado" [value]="customer.state" [colon]="true" [singleLine]="true"></div>
			</ng-template>
		</div>
	</div>
</div>
