import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthStore } from '@lib-auth/auth';
import { PageResizeDirective } from '@lib-core/directives';
import { ENVIRONMENT } from '../../../../../../environments/environment';

@Component({
	standalone: true,
	selector: '[shared-footer]',
	imports: [CommonModule, PageResizeDirective],
	templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
	customerAddresses: any;

	constructor(private authStore: AuthStore) {}

	ngOnInit() {
		this.getCustomerDetails();
	}

	get isAuthenticated(): boolean {
		return this.authStore.isValid();
	}

	getCustomerDetails() {
		this.customerAddresses = ENVIRONMENT.backOfficeSettings.customerLinkAddresses;
	}
}
