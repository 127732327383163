import { Injectable } from '@angular/core';

import { BaseListStore } from '@lib-core/stores';
import { AppliedDiscountsModel } from '@lib-core/models';
import { DISCOUNT_LIST_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class DiscountStore extends BaseListStore<AppliedDiscountsModel> {
	constructor() {
		super(DISCOUNT_LIST_COOKIE_NAME, DISCOUNT_LIST_COOKIE_NAME);
	}
}
