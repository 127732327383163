import { Routes } from '@angular/router';
import { AUTH_ROUTE_NAMES, AuthGuard } from '@lib-auth/auth';
import { LoginComponent, LoginExternalComponent, LogoutComponent } from '@lib-auth/auth/components';

export const AUTH_ROUTE_LIST: Routes = [
	{
		path: AUTH_ROUTE_NAMES.login.path,
		title: AUTH_ROUTE_NAMES.login.title,
		component: LoginComponent,
	},
	{
		path: AUTH_ROUTE_NAMES.external.path,
		title: AUTH_ROUTE_NAMES.external.title,
		component: LoginExternalComponent,
	},
	{
		path: AUTH_ROUTE_NAMES.logout.path,
		title: AUTH_ROUTE_NAMES.logout.title,
		component: LogoutComponent,
		canActivate: [AuthGuard],
	},
];

export const AUTH_INTEGRATION_ROUTE_LIST: Routes = [
	{
		path: AUTH_ROUTE_NAMES.login.path,
		title: AUTH_ROUTE_NAMES.login.title,
		component: LoginComponent,
		data: {
			customerLogin: true,
		},
	},
	{
		path: AUTH_ROUTE_NAMES.external.path,
		title: AUTH_ROUTE_NAMES.external.title,
		component: LoginExternalComponent,
	},
	{
		path: AUTH_ROUTE_NAMES.logout.path,
		title: AUTH_ROUTE_NAMES.logout.title,
		component: LogoutComponent,
		canActivate: [AuthGuard],
	},
];
