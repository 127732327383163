import { Injectable } from '@angular/core';
import { HttpResource } from '.';
import { HttpParams } from '@angular/common/http';
import { ApiResponseModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class ImagesResource {
	constructor(private httpResource: HttpResource) {}

	private baseUrl = 'Images';

	async downloadImagesZip(array: Array<any>, orderCode: string, isAllColorsFromOrder: boolean): Promise<string> {
		const params = new HttpParams({
			fromObject: {
				orderCode: orderCode,
				isAllColorsFromOrder: isAllColorsFromOrder.toString(),
			},
		});

		const response = await this.httpResource
			.post<ApiResponseModel<string>>(`${this.baseUrl}/DownloadImageFromProducts`, array, {
				params: params,
				showLoader: true,
				loaderMessage: 'Baixando suas imagens, por favor aguarde...',
			})
			.toPromise();

		return response.data;
	}
}
