export enum EbookStatusType {
	Error = 0,
	Open = 1,
	Processing = 2,
	Done = 3,
}

export const EbookStatusDescription = Object.freeze({
	[EbookStatusType.Error]: 'Erro',
	[EbookStatusType.Open]: 'Aberto',
	[EbookStatusType.Processing]: 'Processando',
	[EbookStatusType.Done]: 'Concluído',
});
