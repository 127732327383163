<shared-navbar />
<app-hero />
<div class="container-xxl mt-4">
	<div class="row">
		<nav app-gallery-filter class="col-24 col-sm-10 col-md-8 col-xl-5 mb-4" *ngIf="!useMenuOnSidebar"></nav>
		<main class="col-24" [ngClass]="{ 'col-sm-14 col-md-16 col-xl-19': !useMenuOnSidebar }">
			<router-outlet />
		</main>
	</div>
</div>
<app-button-container-edge />
