export const AUTH_ROUTE_NAMES = Object.freeze({
	login: {
		path: 'entrar',
		url: '/entrar',
		title: 'Entrar',
	},
	external: {
		path: 'externo',
		url: '/externo',
		title: 'Autenticação por integração',
	},
	logout: {
		path: 'sair',
		url: '/sair',
		title: 'Sair',
	},
});
