import { Injectable } from '@angular/core';
import { BaseStore } from '@lib-core/stores';
import { SideFilterModel } from '@lib-core/models';
import { SIDE_FILTER_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class SideFilterStore extends BaseStore<SideFilterModel> {
	constructor() {
		super(SIDE_FILTER_COOKIE_NAME);
	}
}
