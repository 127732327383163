export class PagedModel {
	public pageSize: number = 10;
	public currentPage: number = 1;
	public totalCount: number = 0;
	public totalPages: number = 0;

	constructor(pageSize = 10, currentPage = 1) {
		this.pageSize = pageSize;
		this.currentPage = currentPage;
	}
}
