import { map } from 'rxjs';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { CartService } from '@lib-core/services';
import { CartCountStore, CustomerStore } from '@lib-core/stores';

export const CartResolver: ResolveFn<boolean> = route => {
	const customerStore = inject(CustomerStore);
	const cartCountStore = inject(CartCountStore);

	if (!customerStore.isValid()) {
		cartCountStore.set(0);
		return true;
	}

	return inject(CartService)
		.getCart(getCartResolverDetails(route), getCartResolverValidateStock(route))
		.pipe(map(() => true));
};

function getCartResolverDetails(route: ActivatedRouteSnapshot): boolean {
	return route.data['cartResolverDetails'] == true;
}

function getCartResolverValidateStock(route: ActivatedRouteSnapshot): boolean {
	return route.data['validateStock'] == true;
}
