import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from '@lib-core/helpers';

@Pipe({
	name: 'capitalize',
	standalone: true,
})
export class CapitalizePipe implements PipeTransform {
	transform(value: string): string {
		return capitalize(value);
	}
}
