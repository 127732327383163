<div class="overflow-auto" *ngIf="isValid">
	<table class="table table-striped table-bordered" aria-describedby="tabela de grade na sacola">
		<thead>
			<tr>
				<th scope="col">Cor/Tamanho</th>
				@for (size of sizeList; track size) {
				<th scope="col" class="text-center">{{ size | uppercase }}</th>
				}
			</tr>
		</thead>
		<tbody>
			@for (color of colorList; track color.code) {
			<tr *ngIf="isColorValid(color.code)">
				<td>{{ getColorLabel(color) | uppercase }}</td>
				@for (size of sizeList; track size) {
				<td class="text-center" [class]="getTdStatusClass(color.code, size)">
					{{ getQuantity(color.code, size) }}
					<span class="text-warning align-text-top" *ngIf="getWarning(color.code, size)" [ngbTooltip]="getWarning(color.code, size)">
						<i class="bi bi-exclamation-triangle-fill"></i>
					</span>

					<span class="text-danger align-text-top" *ngIf="getError(color.code, size)" [ngbTooltip]="getError(color.code, size)">
						<i class="bi bi-exclamation-circle-fill"></i>
					</span>
				</td>
				}
			</tr>
			}
		</tbody>
		<tfoot>
			<tr>
				<td>Total</td>
				@for (size of sizeList; track size) {
				<td class="text-center">
					{{ getTotalQuantity(size) }}
				</td>
				}
			</tr>
		</tfoot>
	</table>
	<div class="text-end mb-4">
		<h5>Valor total {{ totalNetPrice | brl }}</h5>
	</div>
</div>
