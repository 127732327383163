<div *ngIf="isPageReady">
	@for (key of requestMapKeys; track key) { @for (subKey of requestMap.get(key); track subKey) {
	<app-gallery-filter-viewer-button
		classList="mb-1 me-1"
		[tooltip]="getTooltip(key)"
		[label]="getLabel(key, subKey)"
		(click)="onRemove(key, subKey)"
		(keypress)="onRemove(key, subKey)"
	/>
	} }
</div>
