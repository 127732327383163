import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResource } from '@lib-core/resources';
import { OrderStatusModel } from '@lib-core/models/order-status.model';
import { ApiResponseModel, SalesOrderResponseModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class SalesOrderResource {
	private baseUrl = 'SalesOrder';

	constructor(private httpResource: HttpResource) {}

	queryOrders(
		customerCode: string | null,
		dateFrom: string,
		dateTo: string,
		representativeCode: string | null,
	): Observable<ApiResponseModel<SalesOrderResponseModel>> {
		let url = `${this.baseUrl}/Query?dateFrom=${dateFrom}&dateTo=${dateTo}`;

		if (customerCode) {
			url += `&customerCode=${customerCode}`;
		}

		if (representativeCode) {
			url += `&representativeCode=${representativeCode}`;
		}

		return this.httpResource.get<any>(url, {
			showLoader: true,
			loaderMessage: 'Buscando seus pedidos',
		});
	}

	detailOrderItens(orderCode: string, isDownloadAllColors?: boolean): Observable<ApiResponseModel<any>> {
		const params = new URLSearchParams();
		params.set('orderCode', orderCode);

		if (isDownloadAllColors !== undefined) {
			params.set('isDownloadAllColors', isDownloadAllColors.toString());
		}

		const url = `${this.baseUrl}/DetailOrderItens?${params.toString()}`;

		return this.httpResource.get<any>(url, {
			showLoader: true,
			loaderMessage: 'Buscando produtos do pedido',
		});
	}

	detailOrder(orderCode: string): Observable<ApiResponseModel<any>> {
		return this.httpResource.get<any>(`${this.baseUrl}/DetailOrder?orderCode=${orderCode}`, {
			showLoader: true,
			loaderMessage: 'Buscando detalhes do Pedido',
		});
	}

	listOrderStatus(): Observable<ApiResponseModel<OrderStatusModel[]>> {
		return this.httpResource.get<any>(`${this.baseUrl}/ListOrderStatus`, {
			showLoader: false,
		});
	}

	sendMailWithSalesOrderCopy(salesOrderCode: string) {
		return this.httpResource.get<any>(`${this.baseUrl}/SendMailWithSalesOrderCopy?salesOrderCode=${salesOrderCode}`, {
			showLoader: false,
		});
	}
}
