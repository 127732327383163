import { Injectable } from '@angular/core';

import { CustomerModel } from '@lib-core/models';
import { CUSTOMER_COOKIE_NAME } from '@lib-core/constants';
import { BaseStore, EbookImportStore, SalesTeamStore } from '@lib-core/stores';

@Injectable({
	providedIn: 'root',
})
export class CustomerStore extends BaseStore<CustomerModel> {
	constructor(private ebookImportStore: EbookImportStore, private salesTeamStore: SalesTeamStore) {
		super(CUSTOMER_COOKIE_NAME);
	}

	override set(data: CustomerModel): void {
		super.set(data);
		if (!data) {
			this.ebookImportStore.clear();
		}
	}

	override clear(): void {
		super.clear();
		this.ebookImportStore.clear();
		this.salesTeamStore.clearCustomerSalesTeamList();
	}
}
