import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResource } from '@lib-core/resources/http.resource';
import { LoginResponseDto } from '@lib-core/dtos';
import { ApiResponseModel } from '@lib-core/models';

export interface AuthenticateOptions {
	username: string;
	password: string;
	remember?: boolean;
	loaderMessage?: string;
	customerLogin?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AuthResource {
	private baseUrl = 'Authentication';

	constructor(private httpResource: HttpResource) {}

	authenticate(authOptions: AuthenticateOptions): Observable<ApiResponseModel<LoginResponseDto>> {
		const credentials = {
			username: authOptions.username,
			password: authOptions.password,
		} as any;

		if (authOptions.customerLogin) {
			credentials.isCustomerPortal = authOptions.customerLogin;
		}

		return this.httpResource.post<ApiResponseModel<LoginResponseDto>>(`${this.baseUrl}/Authenticate`, credentials, {
			showLoader: true,
			loaderMessage: authOptions.loaderMessage ?? 'Verificando credenciais',
		});
	}

	authenticateExternal(token: string): Observable<ApiResponseModel<LoginResponseDto>> {
		return this.authenticate({
			username: 'token@token.geo',
			password: token,
			loaderMessage: 'Validando seu token de acesso',
		});
	}
}
