import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-color-sample',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './color-sample.component.html',
	styleUrls: ['./color-sample.component.scss'],
})
export class ColorSampleComponent {
	@Input()
	rgb: string;

	@Input()
	sampleUrl: string;

	@Input()
	size: 'sm' | 'md' | 'lg' | 'xl' = 'sm';

	@Input()
	classList: string;

	@Input()
	clickable: boolean = true;

	@Input()
	disableClick: boolean = false;

	@Input()
	disableSelection: boolean = false;

	@Output()
	colorDeselected = new EventEmitter<void>();

	@Output()
	colorSelected = new EventEmitter<string>();

	get sizeClass() {
		return `color-sample--size--${this.size}`;
	}

	get clickableClass() {
		return this.clickable && !this.disableSelection ? 'color-sample--clickable' : '';
	}

	get internalClassList() {
		return `${this.sizeClass} ${this.clickableClass}`;
	}

	onClick() {
		if (this.clickable) {
			this.colorSelected.emit(this.rgb);
			return;
		}
		this.colorDeselected.emit();
	}

	resetSelection() {
		this.colorDeselected.emit();
	}
}
