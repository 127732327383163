import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';

import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { isObjectNullOrEmpty } from '@lib-core/helpers';

import { PageTitleComponent } from '@lib-shared/components';
import { CustomerModel, RepresentativeModel } from '@lib-core/models';
import { CustomerStore, RepresentativeStore } from '@lib-core/stores';
import { GroupBoxComponent, LabelValueComponent } from '@lib-core/components';

@Component({
	standalone: true,
	selector: 'shared-account-info',
	imports: [NgbAccordionModule, LabelValueComponent, CommonModule, GroupBoxComponent, PageTitleComponent],
	templateUrl: './account-info.component.html',
})
export class AccountInfoComponent {
	customer: CustomerModel;
	representative: RepresentativeModel;

	constructor(private customerStore: CustomerStore, private representativeStore: RepresentativeStore) {
		effect(() => {
			this.customer = this.customerStore.get();
		});

		effect(() => {
			this.representative = this.representativeStore.get();
		});
	}

	get isRepresentative(): boolean {
		return !isObjectNullOrEmpty(this.representative);
	}

	get isCustomer(): boolean {
		return !this.isRepresentative && !isObjectNullOrEmpty(this.customer);
	}
}
