<div class="d-flex align-items-center justify-content-between m-0 py-2" [class]="classList">
	<h2 [ngClass]="{ 'mb-2': !showLine }">{{ pageTitle }}</h2>
	<ng-container *ngTemplateOutlet="rightSlot"></ng-container>
</div>

<hr class="mt-0 mb-2" *ngIf="showLine" [ngClass]="{ 'mb-4': !hasSubtitle }" />

<div *ngIf="hasSubtitle">
	<h6 class="mb-4">{{ pageSubtitle }}</h6>
</div>
