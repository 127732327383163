import { Observable, ReplaySubject } from 'rxjs';

import { isObject } from '@lib-core/helpers/object.helper';
import { isStringNullOrEmpty } from '@lib-core/helpers/string.helper';

function toBase64(data: any): string {
	if (isStringNullOrEmpty(data)) {
		return null;
	}
	if (isObject(data)) {
		data = JSON.stringify(data);
	}
	return btoa(data);
}

function fileToBase64(input: HTMLInputElement): Observable<string> {
	const result = new ReplaySubject<string>(1);
	const reader = new FileReader();
	const file = input.files[0];
	reader.readAsDataURL(file);
	reader.onload = () => {
		if (typeof reader.result !== 'string') {
			throw new Error('Invalid file type.');
		}
		const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
		result.next(base64String);
	};
	return result;
}

function fromBase64(data: string): string {
	if (isStringNullOrEmpty(data)) {
		return null;
	}

	return atob(data);
}

export { toBase64, fromBase64, fileToBase64 };
