import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';

import { BaseEntityModel } from '@lib-core/models';
import { ColorSampleComponent } from '@proj-b2b/app/components/color-sample/color-sample.component';
import { CustomCollapseComponent } from '@proj-b2b/app/components/custom-collapse/custom-collapse.component';
import { capitalize, isStringNullOrEmpty } from '@lib-core/helpers';

@Component({
	standalone: true,
	selector: 'app-gallery-filter-group',
	imports: [CommonModule, FormsModule, ColorSampleComponent, CustomCollapseComponent],
	styleUrl: './gallery-filter-group.component.scss',
	templateUrl: './gallery-filter-group.component.html',
})
export class GalleryFilterGroupComponent implements AfterViewInit, OnChanges, OnDestroy {
	searchTerm: string = null;
	selectedList: string[] = [];
	searchDebounce: any = null;
	originalEntityList: BaseEntityModel[] = [];

	@Input()
	label: string = null;

	@Input()
	height: number = 180;

	@Input()
	search: boolean = false;

	@Input()
	searchLabel: string = 'Pesquisar';

	@Input()
	info: boolean = true;

	@Input()
	hasSample: boolean = false;

	@Input()
	capitalize: boolean = true;

	@Input()
	showCode: boolean = false;

	@Input()
	entityList: any[] = [];

	@Input()
	value: string[] = null;

	@Input()
	classList: string = '';

	@Output()
	onChange = new EventEmitter<BaseEntityModel[]>();

	ngAfterViewInit() {
		this.value?.forEach(code => {
			if (!this.selectedList.includes(code)) {
				this.addItemToList(code, false);
			}
		});
	}

	ngOnChanges(changes: any) {
		if (changes?.value) {
			this.selectedList = changes.value.currentValue;
		}
		this.setListBackup();
		this.restoreSearch();
	}

	ngOnDestroy() {
		if (this.searchDebounce) {
			clearTimeout(this.searchDebounce);
		}
	}

	get isValid() {
		return this.entityList?.length > 0;
	}

	get calculateHeight() {
		return this.entityList.length * 30;
	}

	get itemsTotalCountDescription() {
		return `Total: ${this.entityList?.length}`;
	}

	get itemsSelectedCountDescription() {
		return `Selecionado(s): ${this.selectedList.length}`;
	}

	get collapse() {
		return this.selectedList.length == 0;
	}

	setListBackup() {
		this.originalEntityList = [...this.entityList];
	}

	restoreListBackup() {
		this.entityList = [...this.originalEntityList];
	}

	restoreSearch() {
		if (!isStringNullOrEmpty(this.searchTerm)) {
			this.performSearch(this.searchTerm);
		}
	}

	clear() {
		this.selectedList = [];
		this.searchTerm = null;
		this.restoreListBackup();
	}

	onStateChange(code: string, checked: boolean) {
		return checked ? this.addItemToList(code) : this.removeItemFromList(code);
	}

	getItemDescription(item: BaseEntityModel) {
		return `${this.capitalize ? capitalize(item.description) : item.description}${this.showCode ? ' - ' + item.code : ''}`;
	}

	addItemToList(code: string, triggerChange = true) {
		this.selectedList.push(code);
		if (triggerChange) {
			this.triggerChange();
		}
	}

	removeItemFromList(code: string) {
		const index = this.selectedList.indexOf(code);
		this.selectedList.splice(index, 1);
		this.triggerChange();
	}

	isItemSelected(code: string) {
		return this.selectedList.includes(code);
	}

	onSearch() {
		if (this.searchDebounce) {
			clearTimeout(this.searchDebounce);
		}

		this.searchDebounce = setTimeout(() => {
			this.searchDebounce = null;
			this.performSearch(this.searchTerm);
		}, 600);
	}

	performSearch(searchValue: string) {
		this.restoreListBackup();

		if (isStringNullOrEmpty(searchValue)) {
			return;
		}

		this.entityList = this.entityList.filter(item => item.description?.toLowerCase().includes(searchValue.toLowerCase()));
	}

	triggerChange() {
		this.onChange.emit(this.originalEntityList.filter(item => this.selectedList.includes(item.code)));
	}
}
