import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from '@lib-shared/components';

@Component({
	standalone: true,
	selector: 'app-customer-wrapper',
	imports: [CommonModule, RouterModule, NavbarComponent],
	templateUrl: './customer-wrapper.component.html',
})
export class CustomerWrapperComponent {}
