import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, effect, QueryList, ViewChildren } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CapitalizePipe } from '@lib-core/pipes';
import { GalleryRequestDto } from '@lib-core/dtos';
import { GalleryFilterGroupComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter-group/gallery-filter-group.component';
import { GalleryFilterViewerComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter-viewer/gallery-filter-viewer.component';
import { BaseEntityModel, SideFilterModel } from '@lib-core/models';
import { GalleryRequestStore, SideFilterStore } from '@lib-core/stores';
import { GALLERY_DEFAULT_PAGE, GALLERY_DEFAULT_PAGE_SIZE } from '@lib-core/constants';

@Component({
	standalone: true,
	selector: 'core-gallery-filters',
	imports: [FormsModule, CommonModule, CapitalizePipe, NgbTooltipModule, GalleryFilterGroupComponent, GalleryFilterViewerComponent],
	templateUrl: './gallery-filters.component.html',
})
export class GalleryFiltersComponent {
	filter: SideFilterModel;
	request: GalleryRequestDto;
	filterViewerCount: number;

	@ViewChildren(GalleryFilterGroupComponent)
	filterCheckboxList: QueryList<GalleryFilterGroupComponent>;

	constructor(private sideFilterStore: SideFilterStore, private galleryRequestStore: GalleryRequestStore) {
		effect(() => {
			this.filter = this.sideFilterStore.get();
			this.removeInvalidColors();
		});

		effect(() => {
			this.request = this.galleryRequestStore.get();
		});
	}

	get sizeList(): BaseEntityModel[] {
		return this.filter?.sizes.map(size => {
			return { code: size.size, description: size.size } as BaseEntityModel;
		});
	}

	updateRequest() {
		this.galleryRequestStore.set(this.request);
	}

	removeInvalidColors() {
		if (this.filter?.colors) {
			this.filter.colors = this.filter.colors?.filter(color => color?.rgb);
		}
	}

	clear() {
		this.clearCheckboxList();
		this.clearListFilters();
		this.updateRequest();
	}

	clearCheckboxList() {
		this.filterCheckboxList.forEach(c => c.clear());
	}

	updateFilterViewerCount(count: number) {
		this.filterViewerCount = count;
	}

	clearListFilters() {
		if (this.request == null) {
			return;
		}

		this.request.sizes = [];
		this.request.brands = [];
		this.request.colors = [];
		this.request.genders = [];
		this.request.species = [];
		this.request.seasons = [];
		this.request.segments = [];
		this.request.categories = [];
		this.request.collections = [];
		this.request.productGroups = [];
		this.request.page = GALLERY_DEFAULT_PAGE;
		this.request.pageSize = GALLERY_DEFAULT_PAGE_SIZE;
	}

	onFilterGroupChange(propName: string, values: BaseEntityModel[]) {
		this.request[propName] = values.map(item => item.code);
		this.request.page = GALLERY_DEFAULT_PAGE;
		this.request.pageSize = GALLERY_DEFAULT_PAGE_SIZE;
		this.updateRequest();
	}
}
