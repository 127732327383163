import { CommonModule } from '@angular/common';
import { NgbAccordionModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, ViewChild, effect } from '@angular/core';

import { CpfCnpjPipe } from '@lib-core/pipes';
import { CustomerService } from '@lib-core/services';
import { DataTablesModule } from 'angular-datatables';
import { PageTitleComponent } from '@lib-shared/components';
import { RepresentativeStore } from '@lib-core/stores';
import { CustomerModel, RepresentativeModel } from '@lib-core/models';
import { GroupBoxComponent, LabelValueComponent } from '@lib-core/components';
import { getDataTableOptions, getDataTableOrderByIndex } from '@lib-core/config';

@Component({
	standalone: true,
	selector: 'lib-share-account-customers',
	imports: [CommonModule, NgbAccordionModule, LabelValueComponent, GroupBoxComponent, DataTablesModule, CpfCnpjPipe, PageTitleComponent],
	templateUrl: './account-customers.component.html',
})
export class AccountCustomersComponent {
	dtOptions = { ...getDataTableOptions(), ...getDataTableOrderByIndex(1) };
	customerList: CustomerModel[];
	representative: RepresentativeModel;
	selectedCustomer: CustomerModel;

	@ViewChild('customerDetailsModal')
	customerDetailsModal: ElementRef;

	constructor(private representativeStore: RepresentativeStore, private customerService: CustomerService, private modalService: NgbModal) {
		effect(() => {
			this.representative = this.representativeStore.get();
			this.queryCustomers();
		});
	}

	queryCustomers() {
		this.customerService.listCustomersByRepresentative(this.representative?.code).subscribe(response => {
			this.customerList = response.data;
		});
	}

	openCustomerDetails(customer: CustomerModel) {
		this.selectedCustomer = customer;
		this.modalService.open(this.customerDetailsModal, { size: 'lg', centered: true }).result.then(
			() => {},
			() => {},
		);
	}
}
