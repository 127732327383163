import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, effect } from '@angular/core';

import { SideFilterModel } from '@lib-core/models';
import { GalleryRequestDto } from '@lib-core/dtos';
import { GalleryRequestStore, SideFilterStore } from '@lib-core/stores';
import { GalleryFilterViewerButtonComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter-viewer/components/gallery-filter-viewer-button/gallery-filter-viewer-button.component';

const FILTER_PROP_LIST = {
	collections: 'Coleção',
	seasons: 'Estação',
	sizes: 'Tamanho',
	brands: 'Marca',
	colors: 'Cor',
	species: 'Espécie',
	genders: 'Gênero',
	segments: 'Segmento',
	categories: 'Categoria',
	productGroups: 'Grupo de Produto',
};

@Component({
	standalone: true,
	imports: [CommonModule, GalleryFilterViewerButtonComponent],
	selector: 'app-gallery-filter-viewer',
	templateUrl: './gallery-filter-viewer.component.html',
})
export class GalleryFilterViewerComponent {
	filter: SideFilterModel = null;
	filterMap = new Map<string, string>();

	request: GalleryRequestDto = null;
	requestMap = new Map<string, string[]>();

	@Output()
	change = new EventEmitter<number>();

	constructor(
		private sideFilterStore: SideFilterStore,
		private galleryRequestStore: GalleryRequestStore
	) {
		effect(() => {
			this.filter = this.sideFilterStore.get();
			this.updateFilterMap();
		});

		effect(() => {
			this.request = this.galleryRequestStore.get();
			this.updateRequestMap();
			this.triggerChange();
		});
	}

	get isPageReady() {
		return this.filter && this.request && this.requestMap.size > 0;
	}

	get requestMapKeys() {
		return Array.from(this.requestMap.keys());
	}

	get filterMapKeys() {
		return Array.from(this.filterMap.keys());
	}

	updateRequestMap() {
		this.requestMap.clear();

		Object.keys(FILTER_PROP_LIST).forEach((prop) => {
			const value = this.request[prop];

			if (!value || value?.length == 0) {
				return;
			}

			if (value instanceof Array) {
				this.requestMap.set(prop, value);
				return;
			}

			this.requestMap.set(prop, [value]);
		});
	}

	updateFilterMap() {
		this.filterMap.clear();
		const filterKeys = this.filter ? Object.keys(this.filter) : [];

		filterKeys.forEach((filterKey) => {
			this.filter[filterKey]?.forEach((item) => {
				this.filterMap.set(filterKey + (item.code ?? item.size), item.description ?? item.size);
			});
		});
	}

	getKey(prop: string) {
		return prop + this.request[prop];
	}

	getLabel(prop: string, value: string) {
		return this.filterMap.get(prop + value);
	}

	getTooltip(prop: string) {
		return FILTER_PROP_LIST[prop];
	}

	triggerChange() {
		this.change.emit(this.requestMap.size);
	}

	onRemove(prop: string, value: string) {
		this.request[prop] = this.request[prop].filter((item) => item !== value);
		this.galleryRequestStore.set(this.request);
	}
}
