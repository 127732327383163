export const CUSTOMER_PATH = 'cliente';
export const CUSTOMER_ROUTE = `/${CUSTOMER_PATH}`;

export const CUSTOMER_ROUTE_NAMES = Object.freeze({
	choose: {
		path: 'selecionar',
		url: `${CUSTOMER_ROUTE}/selecionar`,
		title: 'Selecionar cliente',
	},
});
