<button class="btn btn-primary w-100 mb-2" (click)="confirmGenerate()" *ngIf="!hideGenerate">Criar um novo</button>
<div *ngIf="!hideImport">
	<input #input type="file" id="input-ebook" class="file-input d-none" (change)="confirmImport($event)" />
	<label for="input-ebook" class="btn btn-outline-secondary" [class]="importButtonClassList">Importar</label>
</div>
<button class="btn btn-link mt-2 w-100" (click)="goToMyEbooks()" *ngIf="!hideGoToEbooks">
	Ir para meus e-books
	<i class="bi bi-arrow-right"></i>
</button>

<ng-template #maxCountModal let-modal>
	<div class="modal-header bg-warning">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">Por favor selecione no máximo {{ maxEbookCount }} produtos para gerar o e-book.</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.dismiss()">Confirmar</button>
	</div>
</ng-template>

<ng-template #generateModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p>
					Tem certeza que gostaria de gerar um novo e-book com total de
					{{ response?.totalCount }} produto(s)?
				</p>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
		<button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirmar</button>
	</div>
</ng-template>

<ng-template #importModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p>Sua sacola de compras já contém alguns itens. Ao importar um e-book, você deve escolher umas das opções abaixo:</p>
				<ul>
					<li>
						<p class="fw-semibold">Mesclar com os itens atuais</p>
						<p>Os itens do e-book serão adicionados a sua sacola sem substituir os itens atuais.</p>
					</li>
					<li>
						<p class="fw-semibold">Substituir os itens pelos novos do e-book</p>
						<p>Os itens do e-book substituirão os itens atuais na sua sacola.</p>
					</li>
				</ul>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
		<button type="button" class="btn btn-info" (click)="modal.close('merge')">Mesclar</button>
		<button type="button" class="btn btn-danger" (click)="modal.close('replace')">Substituir</button>
	</div>
</ng-template>

<ng-template #modalitySwitchErrorModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Erro!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p>Não é possível importar e-book gerado para modalidade diferente da seleciona usando a opção "mesclar".</p>
				<p>Você pode tentar uma das alternativas abaixo:</p>
				<ul>
					<li>
						<p class="fw-semibold">Importar novamente utilizando a opção "substituir"</p>
						<p>Os produtos do e-book serão adicionados a uma nova sacola de compras.</p>
					</li>
					<li>
						<p class="fw-semibold">Limpar sua sacola de compras e importar seu e-book</p>
						<p>O produtos serão importados sacola normalmente.</p>
					</li>
				</ul>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Entendido</button>
	</div>
</ng-template>

<ng-template #modalitySwitchModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p>O e-book importado foi gerado para outra modalidade de venda. Deseja alterar a modalidade atual e prosseguir com a importação?</p>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
		<button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirmar</button>
	</div>
</ng-template>
