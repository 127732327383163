export class InvoiceRequestDto {
	user: string;
	chaveNFE: string;
	codCliente: string;
	codEmpresa: string;
	emissaoDe: string;
	emissaoAte: string;
	notaFiscal: string;
	venctoDe: string;
	venctoAte: string;
	password: string;
	cnpjs: string[];
	clientes: string[];
	representantes: string[];


	constructor() {
		this.user = '';
		this.chaveNFE = '';
		this.codCliente = '';
		this.codEmpresa = '';
		this.emissaoAte = '';
		this.emissaoDe = '';
		this.notaFiscal = '';
		this.password = '';
		this.venctoAte = '';
		this.venctoDe = '';
		this.cnpjs = [];
		this.clientes = [];
		this.representantes = [];
	}
}
