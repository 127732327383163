import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';

import { MIMEType } from '@lib-core/enums';
import { CartService } from '@lib-core/services';
import { EBookResource } from '@lib-core/resources';
import { RepresentativeStore } from '@lib-core/stores';
import { EbookRequestDto, GalleryRequestDto } from '@lib-core/dtos';
import { download, isStringNullOrEmpty } from '@lib-core/helpers';
import { ApiPagedResponseModel, ApiResponseModel, EbookModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class EBookService {
	constructor(private cartService: CartService, private ebookResource: EBookResource, private representativeStore: RepresentativeStore) {}

	list(request: EbookRequestDto, showLoader: boolean = true): Observable<ApiPagedResponseModel<EbookModel[]>> {
		const options = {
			showLoader,
			loaderMessage: 'Buscando seus arquivos',
		};
		return this.ebookResource.list(request, options);
	}

	generate(filter: GalleryRequestDto): Observable<ApiResponseModel<boolean>> {
		filter.representativeCode = this.representativeStore.get()?.code;
		return this.ebookResource.generate(filter);
	}

	import(file: File, replace = true): Observable<ApiResponseModel<boolean>> {
		const observable = new Subject<ApiResponseModel<boolean>>();

		if (isStringNullOrEmpty(file?.name)) {
			observable.next(new ApiResponseModel(false));
			observable.complete();
			return observable;
		}

		const cartRequest = this.cartService.createRequest();

		this.ebookResource
			.import(file, cartRequest, replace)
			.pipe(take(1))
			.subscribe(response => {
				observable.next(response);
				observable.complete();
			});

		return observable;
	}

	download(ebookId: number): void {
		this.ebookResource.download(ebookId).subscribe(response => {
			if (response.success) {
				download(response.data, 'teste', MIMEType.Pdf);
			}
		});
	}
}
