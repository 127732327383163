// COMPONENTE SERÁ EXCLUÍDO QUANDO VIRAR 100% DO PORTAL

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENVIRONMENT } from '../../../../../../environments/environment';

import { RouterService, SidebarService } from '@lib-core/services';
import { GalleryFiltersComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filters/gallery-filters.component';
import { GalleryFilterModalityComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter-modality/gallery-filter-modality.component';
import { GalleryFilterSalesTeamComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter-sales-team/gallery-filter-sales-team.component';
import { GALLERY_PATH } from '@proj-b2b/app/modules/gallery/gallery.constants';

@Component({
	standalone: true,
	selector: 'core-sidebar',
	imports: [CommonModule, GalleryFilterModalityComponent, GalleryFilterSalesTeamComponent, GalleryFiltersComponent],
	styleUrl: './sidebar.component.scss',
	templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
	constructor(private sidebarService: SidebarService, private routerService: RouterService) {}

	get isVisible(): boolean {
		return this.sidebarService.isVisible;
	}

	get portalUrl(): string {
		return ENVIRONMENT.backOfficeSettings.portalUrl;
	}

	get isOnGalleryRoute(): boolean {
		return this.routerService.getCurrentUrl().includes(GALLERY_PATH);
	}

	get useMenuOnSidebar(): boolean {
		return ENVIRONMENT.backOfficeSettings.useMenuOnSidebar;
	}

	close(): void {
		this.sidebarService.close();
	}
}
