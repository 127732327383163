import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'padLeft',
	standalone: true,
})
export class PadLeftPipe implements PipeTransform {
	transform(value: any, length: number, character: string = '0'): any {
		return value.toString().padStart(length, character);
	}
}
