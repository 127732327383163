import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResource, HttpResourceOptions } from '@lib-core/resources';
import { CartRequestDto, EbookRequestDto, GalleryRequestDto } from '@lib-core/dtos';
import { ApiPagedResponseModel, ApiResponseModel, EbookModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class EBookResource {
	private baseUrl = 'EBook';

	constructor(private httpResource: HttpResource) {}

	generate(filter: GalleryRequestDto): Observable<ApiResponseModel<boolean>> {
		return this.httpResource.post<ApiResponseModel<boolean>>(`${this.baseUrl}/Generate`, filter);
	}

	import(file: File, cartRequest: CartRequestDto, replace = false): Observable<ApiResponseModel<boolean>> {
		const body = new FormData();
		body.append('ebookFile', file, file.name);
		body.append('cartRequest', JSON.stringify(cartRequest));
		body.append('replace', replace.toString());
		return this.httpResource.post(`${this.baseUrl}/Import`, body, {
			showLoader: true,
			loaderMessage: 'Importando seu arquivo',
		});
	}

	list(request: EbookRequestDto, options: HttpResourceOptions): Observable<ApiPagedResponseModel<EbookModel[]>> {
		return this.httpResource.post<ApiPagedResponseModel<EbookModel[]>>(`${this.baseUrl}/List`, request, options);
	}

	download(id: number): Observable<ApiResponseModel<string>> {
		return this.httpResource.get<ApiResponseModel<string>>(`${this.baseUrl}/Download/${id}`, {
			showLoader: true,
			loaderMessage: 'Baixando seu arquivo',
		});
	}
}
