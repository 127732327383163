import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cpfCnpj',
	standalone: true,
})
export class CpfCnpjPipe implements PipeTransform {
	transform(value: string): any {
		if (value) {
			value = value.replace(/\D/g, '');
			if (value.length > 14) value = value.substring(0, 14);
			value = value.replace(TEMPLATES[value.length].regex, TEMPLATES[value.length].mask);
		}
		return value;
	}
}

const TEMPLATES: any = {
	4: {
		regex: /(\d{3})(\d+)/,
		mask: '$1.$2',
	},
	5: {
		regex: /(\d{3})(\d+)/,
		mask: '$1.$2',
	},
	6: {
		regex: /(\d{3})(\d+)/,
		mask: '$1.$2',
	},
	7: {
		regex: /(\d{3})(\d{3})(\d+)/,
		mask: '$1.$2.$3',
	},
	8: {
		regex: /(\d{3})(\d{3})(\d+)/,
		mask: '$1.$2.$3',
	},
	9: {
		regex: /(\d{3})(\d{3})(\d+)/,
		mask: '$1.$2.$3',
	},
	10: {
		regex: /(\d{3})(\d{3})(\d{3})(\d+)/,
		mask: '$1.$2.$3-$4',
	},
	11: {
		regex: /(\d{3})(\d{3})(\d{3})(\d+)/,
		mask: '$1.$2.$3-$4',
	},
	12: {
		regex: /(\d{2})(\d{3})(\d{3})(\d+)/,
		mask: '$1.$2.$3/$4',
	},
	13: {
		regex: /(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/,
		mask: '$1.$2.$3/$4-$5',
	},
	14: {
		regex: /(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/,
		mask: '$1.$2.$3/$4-$5',
	},
};
