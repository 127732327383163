import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { download } from '@lib-core/helpers';
import { EbookModel } from '@lib-core/models';
import { EBookService, ToastService } from '@lib-core/services';
import { EbookRequestDto } from '@lib-core/dtos';
import { PageTitleComponent } from '@lib-shared/components';
import { DateLongPipe, PadLeftPipe } from '@lib-core/pipes';
import { CustomerStore, RepresentativeStore } from '@lib-core/stores';
import { EbookStatusType, EbookStatusDescription, MIMEType } from '@lib-core/enums';
import { getDataTableOptions, getDataTableOrderByIndex } from '@lib-core/config';

@Component({
	imports: [CommonModule, DateLongPipe, PadLeftPipe, PageTitleComponent, NgbPaginationModule, DataTablesModule],
	standalone: true,
	selector: 'lib-shared-account-ebooks',
	templateUrl: './account-ebooks.component.html',
})
export class AccountEbooksComponent implements OnInit, OnDestroy {
	dtOptions = {
		...getDataTableOptions(),
		...getDataTableOrderByIndex(0, 'desc'),
	};

	request: EbookRequestDto;
	ebookList: EbookModel[];
	subscription: Subscription;

	constructor(
		private ebookService: EBookService,
		private customerStore: CustomerStore,
		private representativeStore: RepresentativeStore,
		private toastService: ToastService,
	) {
		this.request = new EbookRequestDto();
	}

	get hasProcessing(): boolean {
		return this.ebookList.find(ebook => ebook.status === EbookStatusType.Processing) !== undefined;
	}

	ngOnInit() {
		this.query();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	query(): void {
		this.ebookList = null;
		this.request.pageSize = 500;
		this.request.customerCode = this.customerStore.get()?.code;
		this.request.representativeCode = this.representativeStore.get()?.code;

		this.subscription = this.ebookService.list(this.request).subscribe({
			next: response => {
				this.ebookList = null;
				if (response.success) {
					this.ebookList = response.data;
					return;
				}
				this.ebookList = [];
				this.showError();
			},
			error: () => {
				this.showError();
			}
		});
	}

	showError(): void {
		this.toastService.danger('Erro ao carregar sua lista de e-books. Tente novamente mais tarde.');
	}

	getStatusDescription(ebook: EbookModel): string {
		return EbookStatusDescription[ebook.status];
	}

	getStatusClass(ebook: EbookModel): string {
		switch (ebook.status) {
			case EbookStatusType.Open:
			case EbookStatusType.Processing:
				return 'text-warning';
			case EbookStatusType.Done:
				return 'text-success';
			case EbookStatusType.Error:
				return 'text-danger';
			default:
				return '';
		}
	}

	canDownload(ebook: EbookModel): boolean {
		return ebook.status === EbookStatusType.Done;
	}

	download(ebook: EbookModel): void {
		if (!this.canDownload(ebook)) {
			return;
		}
		download(ebook.fileUrl, 'teste', MIMEType.Pdf);
	}
}
