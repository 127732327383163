import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CapitalizePipe } from '@lib-core/pipes';

@Component({
	standalone: true,
	imports: [CommonModule, NgbTooltipModule, CapitalizePipe],
	selector: 'app-gallery-filter-viewer-button',
	templateUrl: './gallery-filter-viewer-button.component.html',
})
export class GalleryFilterViewerButtonComponent {
	@Input()
	label = null;

	@Input()
	tooltip = null;

	@Input()
	classList: string = null;

	@Output()
	click = new EventEmitter<void>();

	get isValid() {
		return this.buttonLabel !== null && this.buttonLabel !== '';
	}

	get buttonLabel() {
		return this.label || '';
	}

	get containerClassList() {
		return this.classList || '';
	}

	triggerClick($event: any) {
		$event.stopPropagation();
		this.click.emit();
	}
}
