import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'percentFormat',
	standalone: true,
})
export class PercentPipe extends DecimalPipe implements PipeTransform {
	override transform(value: any): any {
		return super.transform(value, '1.1-1') + '%';
	}
}
