import { Routes } from '@angular/router';
import { AuthGuard } from '@lib-auth/auth';

import { IsRepresentativeGuard } from '@lib-core/guards';
import { CustomerSelectComponent } from '@lib-shared/modules/customer/customer-select/customer-select.component';
import { CustomerWrapperComponent } from '@lib-shared/modules/customer/customer-wrapper.component';
import { CUSTOMER_PATH, CUSTOMER_ROUTE_NAMES } from '@lib-shared/modules/customer/customer.constants';

export const CUSTOMER_ROUTE_LIST: Routes = [
	{
		path: CUSTOMER_PATH,
		component: CustomerWrapperComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: CUSTOMER_ROUTE_NAMES.choose.path,
			},
			{
				path: CUSTOMER_ROUTE_NAMES.choose.path,
				title: CUSTOMER_ROUTE_NAMES.choose.title,
				component: CustomerSelectComponent,
				canActivate: [AuthGuard, IsRepresentativeGuard],
			},
		],
	},
];
