import { Routes } from '@angular/router';

import { GALLERY_PATH } from '@proj-b2b/app/modules/gallery/gallery.constants';
import { AUTH_ROUTE_LIST } from '@lib-auth/auth';
import { CART_ROUTE_LIST } from '@proj-b2b/app/modules/cart/cart.routes';
import { GALLERY_ROUTE_LIST } from '@proj-b2b/app/modules/gallery/gallery.routes';
import { PRODUCT_ROUTE_LIST } from '@proj-b2b/app/modules/product/product.routes';
import { ACCOUNT_ROUTE_LIST } from '@proj-b2b/app/modules/account/account.routes';
import { CUSTOMER_ROUTE_LIST } from '@lib-shared/modules/customer';

export const APP_DEFAULT_ROUTE = GALLERY_PATH;

export const APP_ROUTES: Routes = [
	...AUTH_ROUTE_LIST,
	...ACCOUNT_ROUTE_LIST,
	...CART_ROUTE_LIST,
	...GALLERY_ROUTE_LIST,
	...CUSTOMER_ROUTE_LIST,
	...PRODUCT_ROUTE_LIST,
	{
		path: '',
		pathMatch: 'full',
		redirectTo: APP_DEFAULT_ROUTE,
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: APP_DEFAULT_ROUTE,
	},
];
