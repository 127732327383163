import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthStore } from '@lib-auth/auth/auth.store';

export const AuthGuard: CanActivateFn = (route, state) => {
	const router = inject(Router);
	const authStore = inject(AuthStore);

	if (!authStore.isValid()) {
		router.navigate(['/entrar'], { queryParams: { redirectUrl: state.url } });
		return false;
	}
	return true;
};
