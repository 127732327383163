import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink, Routes } from '@angular/router';

import { PageTitleComponent } from '@lib-shared/components';
import { RepresentativeStore } from '@lib-core/stores';
import { ACCOUNT_ROUTE_NAMES } from '@lib-shared/modules/account/account.constants';

interface MenuItem {
	text: string;
	path: string;
	tooltip?: string;
}

@Component({
	standalone: true,
	selector: 'shared-account-menu',
	imports: [CommonModule, RouterLink, NgbTooltipModule, PageTitleComponent],
	templateUrl: './account-menu.component.html',
})
export class AccountMenuComponent implements OnInit {
	menuList: MenuItem[];

	constructor(private router: Router, private representativeStore: RepresentativeStore) {}

	@Input()
	routeList: Routes;

	ngOnInit(): void {
		this.loadMenuList();
	}

	loadMenuList(): void {
		let { children } = this.routeList[0];

		if (!this.representativeStore.isValid()) {
			children = children.filter(r => r.path !== ACCOUNT_ROUTE_NAMES.customers.path);
		}

		this.menuList = children
			.filter(route => route.path && route.title)
			.map(route => {
				return {
					text: route.title?.toString(),
					path: route.path,
				};
			});
	}

	isLinkActive(link: string) {
		return this.router.url.indexOf(link) > -1;
	}
}
