import { Injectable } from '@angular/core';

import { BaseListStore } from '@lib-core/stores/base-list.store';
import { SalesPeriodModel } from '@lib-core/models';
import { SALES_PERIOD_COOKIE_NAME, SALES_PERIOD_LIST_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class SalesPeriodStore extends BaseListStore<SalesPeriodModel> {
	constructor() {
		super(SALES_PERIOD_COOKIE_NAME, SALES_PERIOD_LIST_COOKIE_NAME);
	}
}
