import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { LoginDetailsStore } from '@lib-core/stores';
import { PageClassListDirective } from '@lib-core/directives';
import { AuthService, AuthStore, AuthenticateOptions } from '@lib-auth/auth';

@Component({
	standalone: true,
	selector: 'auth-login',
	imports: [NgbAlert, FormsModule, CommonModule, RouterOutlet, ReactiveFormsModule, PageClassListDirective],
	templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
	error = null;
	submitted = false;
	queryParams: any;
	loginForm!: FormGroup;
	subscription: Subscription;

	@ViewChild('username')
	usernameInput: ElementRef;

	@ViewChild('password')
	passwordInput: ElementRef;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private authStore: AuthStore,
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private loginDetailsStore: LoginDetailsStore,
	) {
		this.registerForm();
		this.redirectIfAuthenticated();
	}

	ngOnInit() {
		this.loadData();
		this.recoverUsername();
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	ngAfterViewInit() {
		this.setFocus();
	}

	get f() {
		return this.loginForm.controls;
	}

	redirectIfAuthenticated(): void {
		if (this.authStore.isValid()) {
			this.redirect();
		}
	}

	loadData(): void {
		this.activatedRoute.queryParams.subscribe(data => {
			this.queryParams = data;
		});
	}

	clear() {
		this.error = null;
		this.submitted = false;
	}

	registerForm() {
		this.loginForm = this.fb.group({
			username: ['', [Validators.required, Validators.minLength(4)]],
			password: ['', [Validators.required, Validators.minLength(8)]],
			remember: [false],
		});
	}

	changeBackground(): void {
		document.body.classList.toggle('bg-primary');
		document.body.classList.toggle('bg-gradient');
	}

	recoverUsername() {
		const data = this.loginDetailsStore.get();
		if (data) {
			this.loginForm.patchValue({ username: data?.username });
			this.loginForm.patchValue({ remember: data?.rememberMe });
		}
	}

	setFocus() {
		if (this.loginForm.value.username) {
			this.passwordInput.nativeElement.focus();
			return;
		}
		this.usernameInput.nativeElement.focus();
	}

	credentials(): AuthenticateOptions {
		const { username, password, remember } = this.loginForm.value;
		return {
			username,
			password,
			remember,
		};
	}

	login() {
		this.submitted = true;

		if (this.loginForm.invalid) {
			return;
		}

		const credentials = this.credentials();

		this.subscription = this.authService.authenticate(credentials).subscribe({
			next: response => {
				if (response?.success) {
					this.redirect();
					return;
				}
				this.error = response.message;
			},
		});
	}

	redirect() {
		if (this.queryParams?.redirectUrl) {
			this.router.navigate([this.queryParams.redirectUrl]);
			return;
		}
		this.router.navigate(['/']);
	}
}
