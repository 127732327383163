import { Injectable } from '@angular/core';
import { BaseListStore } from '@lib-core/stores/base-list.store';
import { RepresentativeModel } from '@lib-core/models';
import { REPRESENTATIVE_COOKIE_NAME, REPRESENTATIVE_LIST_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class RepresentativeStore extends BaseListStore<RepresentativeModel> {
	constructor() {
		super(REPRESENTATIVE_COOKIE_NAME, REPRESENTATIVE_LIST_COOKIE_NAME);
	}
}
