import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CartService } from '@lib-core/services';
import { SalesTeamModel } from '@lib-core/models';
import { CapitalizePipe } from '@lib-core/pipes';
import { GalleryRequestDto } from '@lib-core/dtos';
import { GalleryRequestStore, SalesTeamStore } from '@lib-core/stores';

@Component({
	standalone: true,
	selector: 'core-gallery-filter-sales-team',
	imports: [FormsModule, CommonModule, CapitalizePipe, NgbTooltipModule],
	templateUrl: './gallery-filter-sales-team.component.html',
})
export class GalleryFilterSalesTeamComponent {
	request: GalleryRequestDto;
	salesTeamList: SalesTeamModel[];

	constructor(private salesTeamStore: SalesTeamStore, private galleryRequestStore: GalleryRequestStore, private cartService: CartService) {
		effect(() => {
			this.salesTeamList = this.salesTeamStore.getList();
		});

		effect(() => {
			this.request = this.galleryRequestStore.get();
		});
	}

	get hasCart(): boolean {
		return this.cartService.hasCart;
	}

	get hasCartTooltip(): string {
		return this.cartService.hasCartTooltip;
	}

	updateRequest() {
		this.galleryRequestStore.set(this.request);
	}
}
