function scrollToTop(top = 0): void {
	window.scroll({
		top,
		left: 0,
		behavior: 'smooth',
	});
}

function scrollToElement(elementId: string): void {
	document.getElementById(elementId).scrollIntoView();
}

export function printModalContent(modalContent: string) {
  const iframe = createIframeElement();
  document.body.appendChild(iframe);

  const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

  if (iframeDocument) {
    const stylesheets = getStylesheets();
    writeDocument(iframeDocument, modalContent, stylesheets);

    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
  }

  document.body.removeChild(iframe);
}

function createIframeElement() {
  const iframe = document.createElement('iframe');
  iframe.style.cssText = 'position: absolute; width: 0; height: 0; border: none; margin: 20px;';
  return iframe;
}

function getStylesheets() {
  return Array.from(document.styleSheets)
    .map(styleSheet => {
      try {
        return Array.from(styleSheet.cssRules)
          .map(rule => rule.cssText)
          .join(' ');
      } catch (error) {
        console.warn('Could not access stylesheet: ', error);
        return '';
      }
    })
    .join(' ');
}

function writeDocument(document: Document, content: string, stylesheets: string) {
  document.open();
  document.write(`
    <html lang="pt_BR">
      <head>
        <title>Print Modal</title>
        <style>${stylesheets}</style>
      </head>
      <body>${content}</body>
    </html>
  `);
  document.close();
}

export { scrollToTop, scrollToElement };
