import deepEqual from 'deep-equal';

function isObject(value: any): boolean {
	return value !== null && typeof value === 'object';
}

function isObjectsEqual(a: any, b: any): boolean {
	return deepEqual(a, b);
}

function isObjectsDifferent(a: any, b: any): boolean {
	return !isObjectsEqual(a, b);
}

function isObjectNullOrEmpty(obj: any): boolean {
	return obj === null || obj === undefined || Object.keys(obj).length === 0;
}

function groupBy(array: any[], key: string): any {
	return array?.reduce((acc, item) => {
		const group = item[key];
		acc[group] = [...(acc[group] || []), item];
		return acc;
	}, {});
}

export { isObject, isObjectsEqual, isObjectNullOrEmpty, isObjectsDifferent, groupBy };
