export class SalesReturnProcessoModel {
	public fluxo_id: number = null;
	public etapa_id: number = null;
	public cnpj?: string = null;
	public capa: object = {};
	public motivo: number = null;
	public processo_id: number = null;
	public anexos: SalesReturnProcessoAnexoModel[] = [];
	public listas: SalesReturnProcessoListaModel[] = [];
}

export class SalesReturnProcessoListaModel {
	public uuid?: string = null;
	public fluxo_id: number = null;
	public etapa_id: number = null;
	public lista_id: number = null;
	public cnpj?: string = null;
	public editing?: boolean = false;
	public lista: SalesReturnProcessoListaItemModel[][] = [];
}

export class SalesReturnProcessoAnexoModel {
	public parent?: string = null;
	public replaceFrom?: string = null;
	public processed?: boolean = false;
	public id?: number = null;
	public nome: string = null;
	public etapa?: number = null;
	public base64?: string = null;
	public field?: number = null;
	public src?: string = null;
	public usuarioInclusao?: string = null;
}

export class SalesReturnProcessoListaItemModel {
	public id: number = null;
	public tipo?: number = null;
	public uuid?: string = null;
	public lista_uuid?: string = null;
	public canSelect?: boolean = null;
	public linha?: number = null;
	public valor: any = null;
	public descricao?: string = null;
	public usuarioInclusao?: string = null;
}
