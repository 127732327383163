import { Injectable } from '@angular/core';
import { CartModel } from '@lib-core/models';
import { CART_LIST_COOKIE_NAME } from '@lib-core/constants';
import { BaseStore, CartCountStore } from '@lib-core/stores';

@Injectable({
	providedIn: 'root',
})
export class CartStore extends BaseStore<CartModel> {
	constructor(private cartCountStore: CartCountStore) {
		super(CART_LIST_COOKIE_NAME);
	}

	override set(value: CartModel): void {
		const cart = new CartModel(value);
		this.cartCountStore.set(cart.distinctCartItemList.length);
		super.set(cart);
	}

	override get(): CartModel {
		const cart = super.get();
		return cart?.id ? new CartModel(cart) : null;
	}

	override clear(): void {
		this.cartCountStore.clear();
		super.clear();
	}
}
