export const GALLERY_PATH = 'galeria';
export const GALLERY_ROUTE = `/${GALLERY_PATH}`;

export const GALLERY_ROUTE_NAMES = Object.freeze({
	list: {
		path: 'lista',
		url: `${GALLERY_ROUTE}/lista`,
		title: 'Galeria de produtos',
	},
});
