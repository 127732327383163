<ng-template #customerModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Seleção de cliente</h4>
	</div>
	<div class="modal-body">
		<div *ngIf="customerList">
			<table datatable [dtOptions]="dtOptions" aria-describedby="tabela-devoluções" class="table table-striped w-100">
				<thead>
					<tr>
						<th class="col-3 text-center" scope="col">Código</th>
						<th class="col-11 text-center" scope="col">Nome</th>
						<th class="col-5 text-center" scope="col">CNPJ</th>
						<th class="col-2 text-center" scope="col"></th>
					</tr>
				</thead>
				<tbody class="table-group-divider">
					@for (customer of customerList; track customer.code) {
					<tr class="align-middle" *ngIf="customer.code">
						<td class="text-center">{{ customer.code }}</td>
						<td>{{ customer.name }}</td>
						<td class="text-center">{{ customer.cnpj | cpfCnpj }}</td>
						<td class="text-center">
							<button type="button" class="btn btn-outline-primary" (click)="select(customer)">
								<i class="bi bi-check d-inline d-lg-none"></i>
								<span class="d-none d-lg-inline">Selecionar</span>
							</button>
						</td>
					</tr>
					}
				</tbody>
			</table>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancelar</button>
	</div>
</ng-template>

<ng-template #noSalesTeamModalError let-modal>
	<div class="modal-header bg-danger text-white">
		<h4 class="modal-title" id="modal-basic-title">Erro!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">O Cliente selecionado não possui equipe de vendas cadastrada. Por favor entre em contato com o suporte.</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.dismiss()">Entendido</button>
	</div>
</ng-template>