import { Injectable } from '@angular/core';

import { HttpResource } from '@lib-core/resources';
import { PaymentConditionResponseDto } from '@lib-core/dtos';

@Injectable({
	providedIn: 'root',
})
export class PaymentConditionResource {
	private baseUrl = 'PaymentCondition';

	constructor(private httpResource: HttpResource) {}

	queryPaymentConditionsForCustomer(customerCode: string, modalityCode: string) {
		return this.httpResource.post<PaymentConditionResponseDto>(
			`${this.baseUrl}/GetByCustomer`,
			{
				customerCode,
				modalityCode,
			},
			{
				showLoader: true,
				loaderMessage: 'Buscando condições de pagamento',
			},
		);
	}
}
