import { Injectable } from '@angular/core';

import { HttpResource } from '@lib-core/resources';
import { InvoiceRequestDto } from '@lib-core/dtos';

@Injectable({
	providedIn: 'root',
})
export class InvoiceResource {
	private baseUrl = 'Invoice';

	constructor(private httpResource: HttpResource) {}

	queryInvoices(filter: InvoiceRequestDto) {
		return this.httpResource.post<any>(
			`${this.baseUrl}/List`,
			{ ...filter },
			{
				showLoader: true,
				loaderMessage: 'Buscando suas notas fiscais',
			},
		);
	}

	downloadXml(nfeKey: string) {
		return this.httpResource.get<any>(`${this.baseUrl}/GetXml/${nfeKey}`, {
			showLoader: true,
			loaderMessage: 'Gerando seu arquivo',
		});
	}

	downloadDanfe(nfeKey: string) {
		return this.httpResource.get<any>(`${this.baseUrl}/GetDanfe/${nfeKey}`, {
			showLoader: true,
			loaderMessage: 'Gerando seu arquivo',
		});
	}
}
