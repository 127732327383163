import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RouterService } from '@lib-core/services';

import { RepresentativeStore } from '@lib-core/stores';

export const IsRepresentativeGuard: CanActivateFn = () => {
	const routerService = inject(RouterService);
	const representativeStore = inject(RepresentativeStore);

	if (!representativeStore.isValid()) {
		routerService.goBack();
		return false;
	}

	return true;
};
