import { Injectable } from '@angular/core';
import { GalleryRequestDto } from '@lib-core/dtos';
import { GALLERY_DEFAULT_PAGE, GALLERY_DEFAULT_PAGE_SIZE, GALLERY_REQUEST_COOKIE_NAME } from '@lib-core/constants';
import { BaseStore, CustomerStore, ModalityStore, RepresentativeStore, SalesPeriodStore, SalesTeamStore } from '@lib-core/stores';

@Injectable({
	providedIn: 'root',
})
export class GalleryRequestStore extends BaseStore<GalleryRequestDto> {
	constructor(
		private modalityStore: ModalityStore,
		private customerStore: CustomerStore,
		private salesTeamStore: SalesTeamStore,
		private salesPeriodStore: SalesPeriodStore,
		private representativeStore: RepresentativeStore,
	) {
		super(GALLERY_REQUEST_COOKIE_NAME);
	}

	override set(data: GalleryRequestDto) {
		if (data?.modalityCode) {
			this.modalityStore.setByProp(data.modalityCode);
		}

		if (data?.salesTeamCode) {
			this.salesTeamStore.setByProp(data.salesTeamCode);
		}

		if (data?.salesPeriod) {
			this.salesPeriodStore.setByProp(data.salesPeriod);
		}

		if (data?.representativeCode) {
			this.representativeStore.setByProp(data.representativeCode);
		}

		super.set(data);
	}

	override get(): GalleryRequestDto {
		var request = super.get();

		if (!request) {
			request = new GalleryRequestDto();
		}

		var modalityCode = this.modalityStore.get()?.code;
		if (modalityCode) request.modalityCode = modalityCode;

		// habilitar apenas no programado
		// var salesPeriodCode = this.salesPeriodStore.get()?.code;
		// if (salesPeriodCode) request.salesPeriod = salesPeriodCode;

		const customerCode = this.customerStore.get()?.code;
		if (customerCode) request.customerCode = customerCode;

		var salesTeamCode = this.salesTeamStore.get()?.code;
		if (salesTeamCode) request.salesTeamCode = salesTeamCode;

		var representativeCode = this.representativeStore.get()?.code;
		if (representativeCode) request.representativeCode = representativeCode;

		return request;
	}

	override clear(): void {
		const request = {
			sizes: [],
			brands: [],
			colors: [],
			genders: [],
			species: [],
			seasons: [],
			segments: [],
			categories: [],
			collections: [],
			productGroups: [],
			page: GALLERY_DEFAULT_PAGE,
			pageSize: GALLERY_DEFAULT_PAGE_SIZE,
		} as GalleryRequestDto;
		super.set(request);
	}
}
