import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeroComponent } from '@proj-b2b/app/components/hero/hero.component';
import { NavbarComponent } from '@lib-shared/components';
import { SidebarService } from '@lib-core/services';
import { GalleryFilterComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter/gallery-filter.component';
import { ButtonContainerEdgeComponent } from '@lib-core/components/button-container-edge/button-container-edge';

@Component({
	standalone: true,
	selector: 'app-gallery-wrapper',
	imports: [CommonModule, RouterModule, NavbarComponent, HeroComponent, GalleryFilterComponent, ButtonContainerEdgeComponent],
	templateUrl: './gallery-wrapper.component.html',
})
export class GalleryWrapperComponent {
	constructor(private sidebarService: SidebarService) {}

	get useMenuOnSidebar(): boolean {
		return this.sidebarService.useMenuOnSidebar;
	}
}
